import React from "react";
import IUser from "../types/user.type";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import PayService from "../services/pay.service";
import moment from "moment";
import ILeave from "../types/leave.type";
import LeaveService from "../services/leave.service";
import IPayroll from "../types/payroll.type";
import IPayrollHistory from "../types/payrollhistory.type";
import {toast, ToastContainer} from "react-toastify";
import IDeduction from "../types/deduction.type";
import IPayslip from "../types/payslip.type";

type Props = {
    selectMonth: Date,
    email: string
}

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    selectMonth: Date,
    email: string,
    curPayrolls: IPayroll[],
    activePayrolls: IPayroll[],
    curPayrollHistory: IPayrollHistory[],
    totalSalary: number,
    totalBonus: number,
    totalOfficial: number,
    totalIntern: number,
    allUsers: IUser[],
    emptyPayrollUsers: IUser[],
    resignUsers: IPayroll[],
    leaveRequests: ILeave[],
    reqPayrollDone: boolean,
    reqPayrollHistoryDone: boolean,
    reqUserDone: boolean,
    reqLeaveRequestsDone: boolean,
    newPayroll: IPayroll,
    newInstallment: IDeduction
}

export default class UserPayroll extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser,
            selectMonth: this.props.selectMonth,
            email: this.props.email,
            curPayrolls: [] as IPayroll[],
            activePayrolls: [] as IPayroll[],
            curPayrollHistory: [] as IPayrollHistory[],
            totalSalary: 0,
            totalBonus: 0,
            totalOfficial: 0,
            totalIntern: 0,
            allUsers: [] as IUser[],
            emptyPayrollUsers: [] as IUser[],
            resignUsers: [] as IPayroll[],
            leaveRequests: [] as ILeave[],
            reqPayrollDone: false,
            reqPayrollHistoryDone: false,
            reqUserDone: false,
            reqLeaveRequestsDone: false,
            newPayroll: {} as IPayroll,
            newInstallment: {} as IDeduction,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeEmployeeType = this.handleChangeEmployeeType.bind(this);
        this.handleChangeSalary = this.handleChangeSalary.bind(this);
        this.handleChangeBaseSalary = this.handleChangeBaseSalary.bind(this);
        this.handleChangeBonus = this.handleChangeBonus.bind(this);
        this.handleChangeSalaryRate = this.handleChangeSalaryRate.bind(this);
        this.handleChangeAmercementRate = this.handleChangeAmercementRate.bind(this);
        this.handleChangePaidFull = this.handleChangePaidFull.bind(this);
        this.handleChangeActive = this.handleChangeActive.bind(this);
        this.handleChangeApplyMonth = this.handleChangeApplyMonth.bind(this);
        this.handleChangeApplyYear = this.handleChangeApplyYear.bind(this);
        this.handleChangeApplyDate = this.handleChangeApplyDate.bind(this);
        this.handleChangeReason = this.handleChangeReason.bind(this);
        this.handleChangeAnnualLeave = this.handleChangeAnnualLeave.bind(this);

        this.handleSubmitInstallment = this.handleSubmitInstallment.bind(this);
        this.handleChangeInstallmentEmail = this.handleChangeInstallmentEmail.bind(this);
        this.handleChangeInstallmentMonth = this.handleChangeInstallmentMonth.bind(this);
        this.handleChangeInstallmentYear = this.handleChangeInstallmentYear.bind(this);
        this.handleChangeInstallmentTotalMonths = this.handleChangeInstallmentTotalMonths.bind(this);
        this.handleChangeInstallmentMoney = this.handleChangeInstallmentMoney.bind(this);
        this.handleChangeInstallmentRate = this.handleChangeInstallmentRate.bind(this);
        this.handleChangeInstallmentReason = this.handleChangeInstallmentReason.bind(this);
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                isModerator: user.roles?.includes("ROLE_MODERATOR"),
                isAdmin: user.roles?.includes("ROLE_ADMIN"),
            });
        }
    };

    componentDidUpdate = () => {
        if (this.state.selectMonth != this.props.selectMonth) {
            this.setState({
                selectMonth: this.props.selectMonth,
                curPayrolls: [] as IPayroll[],
                activePayrolls: [] as IPayroll[],
                curPayrollHistory: [] as IPayrollHistory[],
                totalSalary: 0,
                totalBonus: 0,
                leaveRequests: [] as ILeave[],
                reqPayrollDone: false,
                reqPayrollHistoryDone: false,
                reqLeaveRequestsDone: false,
            });
            this.updateData();
        }
    };

    updateData() {
        PayService.getAllPayrolls().then(
            (a: IPayroll[]) => {
                this.setState({
                    reqPayrollDone: true,
                    curPayrolls: a
                });
                if (this.state.reqLeaveRequestsDone && this.state.reqUserDone && this.state.reqPayrollHistoryDone) {
                    this.CalculateTime(a, this.state.curPayrollHistory);
                }
            }
        );
        PayService.getAllPayrollHistory().then(
            (a: IPayrollHistory[]) => {
                this.setState({
                    reqPayrollHistoryDone: true,
                    curPayrollHistory: a
                });
                if (this.state.reqLeaveRequestsDone && this.state.reqUserDone && this.state.reqPayrollDone) {
                    this.CalculateTime(this.state.curPayrolls, a);
                }
            }
        );
        LeaveService.getAllLeaveRequestsByDay(this.props.selectMonth.getDate(), this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
            (a: ILeave[]) => {
                this.setState({
                    reqLeaveRequestsDone: true,
                    leaveRequests: a,
                });
                if (this.state.reqPayrollDone && this.state.reqPayrollHistoryDone && this.state.reqUserDone) {
                    this.CalculateTime(this.state.curPayrolls, this.state.curPayrollHistory);
                }
            }
        );
        if (this.state.allUsers == null || this.state.allUsers.length <= 0) {
            UserService.getAllBasicUsers().then(
                (r: IUser[]) => {
                    this.setState({
                        reqUserDone: true,
                        allUsers: r,
                    });
                    if (this.state.reqLeaveRequestsDone && this.state.reqPayrollDone && this.state.reqPayrollHistoryDone) {
                        this.CalculateTime(this.state.curPayrolls, this.state.curPayrollHistory);
                    }
                });
        }
    };

    CalculateTime(curPayrolls: IPayroll[], curPayrollHistory: IPayrollHistory[]) {
        this.setState({
            totalSalary: 0,
            totalBonus: 0,
            totalOfficial: 0,
            totalIntern: 0,
        });
        let totalSalary = 0;
        let totalBonus = 0;
        let totalOfficial = 0;
        let totalIntern = 0;
        let emptyPayrollUsers = this.state.allUsers;
        let resignUsers = [] as IPayroll[];
        let activePayrolls = [] as IPayroll[];

        if (curPayrolls != null && curPayrolls.length > 0) {
            for (let i = 0; i < curPayrolls.length; i++) {
                let pay = curPayrolls[i] as IPayroll;
                if (pay.active == 1) {
                    totalSalary += Number(pay.salary!) * Number(pay.salary_rate!);
                    totalBonus += Number(pay.bonus!);
                }
                if (this.state.allUsers.find((u) => {
                    return (u.email == pay.email);
                }) != null) {
                    emptyPayrollUsers = emptyPayrollUsers.filter(u => u.email !== pay.email);
                }
            }
        }
        this.state.allUsers.map((user, index) => {
            let payroll = this.state.curPayrolls.find((att) => {
                return (att.email == user.email);
            });
            let payroll_history = this.state.curPayrollHistory.find((att) => {
                return (att.email == user.email);
            });
            if (payroll != null) {
                payroll.picture = user.picture;
                payroll.name = user.name;
                payroll.apply_date = user.resign_date;
                user.payroll = payroll;
                if (payroll.active == 0) {
                    resignUsers.push(payroll);
                } else {
                    activePayrolls.push(payroll);
                    if (payroll.employee_type == 'official') {
                        totalOfficial++;
                    }
                    if (payroll.employee_type == 'probationary' || payroll.employee_type == 'intern' || payroll.employee_type == 'fresher') {
                        totalIntern++;
                    }
                }
            }
            if (payroll_history != null) {
                user.payroll_history = payroll_history;
            }
        });
        this.setState({
            totalSalary: totalSalary,
            totalBonus: totalBonus,
            totalOfficial: totalOfficial,
            totalIntern: totalIntern,
            emptyPayrollUsers: emptyPayrollUsers,
            resignUsers: resignUsers,
            activePayrolls: activePayrolls,
        });
    }

    getTime(time: string) {
        const dt = new Date(time.replace(/-/g, "/"));
        return {
            hour: dt.getHours(),
            minute: dt.getMinutes(),
            second: dt.getSeconds(),
            time: moment(dt).format("HH:mm"),
            date: dt
        };
    };

    CreateLink = (isAdmin: boolean, email: string, picture: string) => {
        if (isAdmin) {
            return (<a href={"/payslip?email=" + email}><img src={picture} alt="user" className="img-circle"
                                                             width="40"/></a>);
        } else {
            return (<img src={picture} alt="user" className="img-circle" width="40"/>);
        }
    };

    ShowLeaveType = (type: string) => {
        switch (type) {
            case 'Casual':
                return (<span className="text-default">{type!.replaceAll('_', ' ')}</span>);
            case 'Sick':
                return (<span className="text-danger">{type!.replaceAll('_', ' ')}</span>);
            case 'Annual':
                return (<span className="text-blue">{type!.replaceAll('_', ' ')}</span>);
            case 'Forgot_Check_In_Out':
                return (<span className="text-warning">{type!.replaceAll('_', ' ')}</span>);
            case 'Come_Late_Leave_Early':
                return (<span className="text-success">{type!.replaceAll('_', ' ')}</span>);
            case 'Work_From_Home':
                return (<span className="text-purple">{type!.replaceAll('_', ' ')}</span>);
        }
    };

    ShowStatus = (leave: ILeave) => {
        if (leave.approve == 0) {
            return (<span className="label label-danger font-weight-100">Declined</span>);
        } else if (leave.approve == 1) {
            return (<span className="label label-success font-weight-100">Approved</span>);
        } else {
            return (<span className="label label-warning font-weight-100">Awaiting</span>);
        }
    };

    ShowLeaveTime = (leave: ILeave) => {
        if (leave.type != "Work_From_Home") {
            return (<span className="text-danger">{leave.reason}</span>);
        }
        switch (leave.leave_time) {
            case 'am':
                return (<span className="text-default">Half Day Application</span>);
            case 'pm':
                return (<span className="text-purple">Half Day Application</span>);
            case 'fd':
                return (<span className="text-success">Full Day Application</span>);
            case 'md':
                return (<span
                    className="text-warning">{moment(leave.end).diff(leave.start, 'days') + 1} Days Application</span>);
        }
    };

    ShowLeaveTime2 = (leave: ILeave) => {
        switch (leave.leave_time) {
            case 'am':
                return (<span className="text-default">Morning</span>);
            case 'pm':
                return (<span className="text-purple">Afternoon</span>);
            case 'fd':
                return (<span className="text-success">Full Day</span>);
            case 'md':
                return (<span className="text-danger">{moment(leave.end).format("ddd, MMM DD")}</span>);
        }
    };

    ShowHeader() {
        return (<tr>
            <th className="footable-visible footable-sortable"></th>
            <th className="footable-visible footable-sortable"> Name</th>
            <th className="footable-visible footable-sortable"> Salary</th>
            <th className="footable-visible footable-sortable"> Bonus</th>
            <th className="footable-visible footable-sortable"> Type</th>
            <th className="footable-visible footable-sortable"> Salary Rate</th>
            <th className="footable-visible footable-sortable"> Annual Leaves</th>
            <th className="footable-visible footable-sortable"> Insurance Salary</th>
            <th className="footable-visible footable-sortable"> Paid Full</th>
            <th className="footable-visible footable-sortable"> Amercement</th>
            <th className="footable-visible footable-sortable"></th>
        </tr>)
    };

    ShowResignHeader() {
        return (<tr>
            <th className="footable-visible footable-sortable"></th>
            <th className="footable-visible footable-sortable"> Resigned</th>
            <th className="footable-visible footable-sortable"> Salary</th>
            <th className="footable-visible footable-sortable"> Bonus</th>
            <th className="footable-visible footable-sortable"> Type</th>
            <th className="footable-visible footable-sortable"> Insurance Salary</th>
            <th className="footable-visible footable-sortable"> Resigned Date</th>
        </tr>)
    };

    CurrencyFormat = (num: number) => {
        return new Intl.NumberFormat('vi-VN', {style: 'currency', currency: 'VND'}).format(num);
    };

    CreatePayroll = (user: IUser) => {
        this.setState({
            newPayroll: {
                id: null,
                user_id: user.id,
                email: user.email,
                name: user.name,
                picture: user.picture,
                paid_full: 0,
                amercement_rate: 1,
                employee_type: 'probationary',
                salary_rate: 1,
                bonus: 0,
                salary: 0,
                base_salary: 0,
                annual_leaves: 0,
                active: 1,
                apply_month: new Date().getUTCMonth() + 1,
                apply_year: new Date().getUTCFullYear(),
                approver: this.state.currentUser.email,
                apply_date: moment().format("YYYY-MM-DD")
            }
        });
    };

    DeleteUser = (user: IUser) => {
        UserService.removeUser(user).then(
            (result) => {
                if (result['code'] == 0) {
                    window.location.reload();
                } else {
                }
            });
    };

    Installment = (payroll: IPayroll, installment: IDeduction) => {
        installment.user_id = payroll.user_id;
        installment.email = payroll.email;
        installment.month = new Date().getUTCMonth() + 1;
        installment.year = new Date().getUTCFullYear();
        installment.installment_rate = 0;
        installment.total_months = 3;
        installment.month_end = new Date().getUTCMonth() + 3;
        installment.year_end = new Date().getUTCFullYear();
        this.setState({
            newInstallment: installment
        });
    };

    UpdatePayroll = (payroll: IPayroll) => {
        payroll.apply_month = new Date().getUTCMonth() + 1;
        payroll.apply_year = new Date().getUTCFullYear();
        payroll.approver = this.state.currentUser.email;
        this.setState({
            newPayroll: payroll
        });
    };

    Resign = (payroll: IPayroll) => {
        payroll.approver = this.state.currentUser.email;
        payroll.apply_date = moment().format("YYYY-MM-DD")
        this.setState({
            newPayroll: payroll
        });
    };

    ShowActionButtons = (payroll: IPayroll, installment: IDeduction) => {
        return (<>
            <button data-toggle="modal" data-target="#update-payroll-modal"
                    className="btn btn-sm btn-rounded btn-outline-info m-l-10"
                    onClick={() => this.UpdatePayroll(payroll)}>Update
            </button>
            <button data-toggle="modal" data-target="#create-installment-modal"
                    className="btn btn-sm btn-rounded btn-outline-success m-l-10"
                    onClick={() => this.Installment(payroll, installment)}>Installment
            </button>
            <button data-toggle="modal" data-target="#resign-modal"
                    className="btn btn-sm btn-rounded btn-outline-danger m-l-10"
                    onClick={() => this.Resign(payroll)}>Resign
            </button>
        </>);
    };

    ShowTable() {
        const {activePayrolls, isAdmin, newInstallment} = this.state;
        if (activePayrolls != null) {
            return activePayrolls.map((att, i) => (
                <tr key={att.id}>
                    <td>{this.CreateLink(isAdmin, att.email!, att.picture!)}</td>
                    <td>{att.name}</td>
                    <td>{this.CurrencyFormat(att.salary!)}</td>
                    <td>{this.CurrencyFormat(att.bonus!)}</td>
                    <td>{att.employee_type!}</td>
                    <td>{Number(att.salary_rate!) * 100}%</td>
                    <td>{att.annual_leaves!}</td>
                    <td>{this.CurrencyFormat(att.base_salary!)}</td>
                    <td>{Number(att.paid_full!) == 1 ? "TRUE" : "FALSE"}</td>
                    <td>{Number(att.amercement_rate!) * 100}%</td>
                    <td>{this.ShowActionButtons(att, newInstallment)}</td>
                </tr>
            ))
        }
    };

    showEmptyPayrollTable = () => {
        const {emptyPayrollUsers, isAdmin} = this.state;
        if (emptyPayrollUsers != null) {
            return emptyPayrollUsers.map((att, i) => (
                <tr style={{backgroundColor: "rgba(251,58,58,0.13)"}}>
                    <td>{this.CreateLink(isAdmin, att.email!, att.picture!)}</td>
                    <td>{att.name}</td>
                    <td colSpan={8}><span className="text-bold-red">Not-set</span></td>
                    <td>
                        <button data-toggle="modal" data-target="#update-payroll-modal"
                                className="btn btn-sm btn-rounded btn-success"
                                onClick={() => this.CreatePayroll(att)}>Create
                        </button>
                        <button className="btn btn-sm btn-rounded btn-danger"
                                onClick={() => this.DeleteUser(att)}>Delete
                        </button>
                    </td>
                </tr>
            ))
        }
    };

    showResignTable = () => {
        const {resignUsers, isAdmin} = this.state;
        if (resignUsers != null) {
            return resignUsers.map((att, i) => (
                <tr key={att.id} style={{backgroundColor: "rgba(251,58,58,0.13)"}}>
                    <td>{this.CreateLink(isAdmin, att.email!, att.picture!)}</td>
                    <td>{att.name}</td>
                    <td>{this.CurrencyFormat(att.salary!)}</td>
                    <td>{this.CurrencyFormat(att.bonus!)}</td>
                    <td>{att.employee_type!}</td>
                    <td>{this.CurrencyFormat(att.base_salary!)}</td>
                    <td>{att.apply_date}</td>
                </tr>
            ))
        }
    };

    ShowToast = (message: string) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    handleChangeEmployeeType(event: React.ChangeEvent<HTMLSelectElement>) {
        let newPayroll = this.state.newPayroll;
        newPayroll.employee_type = event.target.value;
        this.setState({
            newPayroll: newPayroll
        });
    }

    handleChangeSalary(event: React.ChangeEvent<HTMLInputElement>) {
        let newPayroll = this.state.newPayroll;
        newPayroll.salary = Number(event.target.value);
        this.setState({
            newPayroll: newPayroll
        });
    }

    handleChangeBaseSalary(event: React.ChangeEvent<HTMLInputElement>) {
        let newPayroll = this.state.newPayroll;
        newPayroll.base_salary = Number(event.target.value);
        this.setState({
            newPayroll: newPayroll
        });
    }

    handleChangeBonus(event: React.ChangeEvent<HTMLInputElement>) {
        let newPayroll = this.state.newPayroll;
        newPayroll.bonus = Number(event.target.value);
        this.setState({
            newPayroll: newPayroll
        });
    }

    handleChangeSalaryRate(event: React.ChangeEvent<HTMLInputElement>) {
        let newPayroll = this.state.newPayroll;
        newPayroll.salary_rate = Number(event.target.value) / 100;
        this.setState({
            newPayroll: newPayroll
        });
    }

    handleChangeAmercementRate(event: React.ChangeEvent<HTMLInputElement>) {
        let newPayroll = this.state.newPayroll;
        newPayroll.amercement_rate = Number(event.target.value) / 100;
        this.setState({
            newPayroll: newPayroll
        });
    }

    handleChangePaidFull(event: React.ChangeEvent<HTMLSelectElement>) {
        let newPayroll = this.state.newPayroll;
        newPayroll.paid_full = Number(event.target.value);
        this.setState({
            newPayroll: newPayroll
        });
    }

    handleChangeAnnualLeave(event: React.ChangeEvent<HTMLInputElement>) {
        let newPayroll = this.state.newPayroll;
        newPayroll.annual_leaves = Number(event.target.value);
        this.setState({
            newPayroll: newPayroll
        });
    }

    handleChangeActive(event: React.ChangeEvent<HTMLSelectElement>) {
        let newPayroll = this.state.newPayroll;
        newPayroll.active = Number(event.target.value);
        this.setState({
            newPayroll: newPayroll
        });
    }

    handleChangeApplyMonth(event: React.ChangeEvent<HTMLSelectElement>) {
        let newPayroll = this.state.newPayroll;
        newPayroll.apply_month = Number(event.target.value);
        this.setState({
            newPayroll: newPayroll
        });
    }

    handleChangeApplyYear(event: React.ChangeEvent<HTMLSelectElement>) {
        let newPayroll = this.state.newPayroll;
        newPayroll.apply_year = Number(event.target.value);
        this.setState({
            newPayroll: newPayroll
        });
    }

    handleChangeApplyDate(event: React.ChangeEvent<HTMLInputElement>) {
        let newPayroll = this.state.newPayroll;
        newPayroll.apply_date = event.target.value;
        this.setState({
            newPayroll: newPayroll
        });
    }

    handleChangeReason(event: React.ChangeEvent<HTMLInputElement>) {
        let newPayroll = this.state.newPayroll;
        newPayroll.description = event.target.value;
        this.setState({
            newPayroll: newPayroll
        });
    }

    ShowApplyMonth = () => {
        let rows = [];
        for (let i = 1; i <= 12; i++) {
            rows.push(<option value={i} selected={this.state.newPayroll.apply_month == i}>{i}</option>);
        }
        return <select className="form-control form-control-line" name="apply_month"
                       id="apply_month"
                       onChange={this.handleChangeApplyMonth}>
            {rows}
        </select>;
    };

    ShowApplyYear = () => {
        let rows = [];
        const y = new Date().getUTCFullYear();
        for (let i = y; i < y + 2; i++) {
            rows.push(<option value={i} selected={this.state.newPayroll.apply_year == i}>{i}</option>);
        }
        return <select className="form-control form-control-line" name="apply_year"
                       id="apply_year"
                       onChange={this.handleChangeApplyYear}>
            {rows}
        </select>;
    };

    handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        const {newPayroll} = this.state;
        if (newPayroll.salary == null) {
            this.ShowToast("Salary can not be empty!");
            return;
        }
        if (newPayroll.id == null) {
            PayService.createPayroll(newPayroll).then(
                (result) => {
                    if (result['code'] == 0) {
                        //close modal
                        window.location.reload();
                    } else {
                    }
                });
            return;
        }
        PayService.updatePayroll(newPayroll).then(
            (result) => {
                if (result['code'] == 0) {
                    //close modal
                    window.location.reload();
                } else {
                }
            });
    };

    handleSubmitResign: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        const {newPayroll} = this.state;
        newPayroll.active = 0;
        PayService.resign(newPayroll).then(
            (result) => {
                if (result['code'] == 0) {
                    //close modal
                    window.location.reload();
                } else {
                }
            });
    };

    //Installment
    handleChangeInstallmentEmail(event: React.ChangeEvent<HTMLInputElement>) {
        let newInstallment = this.state.newInstallment;
        newInstallment.email = event.target.value;
        this.setState({
            newInstallment: newInstallment
        });
    }

    handleChangeInstallmentMonth(event: React.ChangeEvent<HTMLSelectElement>) {
        let newInstallment = this.state.newInstallment;
        newInstallment.month = Number(event.target.value);
        const d = new Date(newInstallment.year!, newInstallment.month!, 1);
        d.setMonth(d.getMonth() + newInstallment.total_months!);
        newInstallment.month_end = d.getUTCMonth();
        newInstallment.year_end = d.getUTCFullYear();
        if (newInstallment.month_end == 0) {
            newInstallment.month_end = 12;
            newInstallment.year_end--;
        }
        this.setState({
            newInstallment: newInstallment
        });
    }

    handleChangeInstallmentYear(event: React.ChangeEvent<HTMLSelectElement>) {
        let newInstallment = this.state.newInstallment;
        newInstallment.year = Number(event.target.value);
        const d = new Date(newInstallment.year!, newInstallment.month!, 1);
        d.setMonth(d.getMonth() + newInstallment.total_months!);
        newInstallment.month_end = d.getUTCMonth();
        newInstallment.year_end = d.getUTCFullYear();
        if (newInstallment.month_end == 0) {
            newInstallment.month_end = 12;
            newInstallment.year_end--;
        }
        this.setState({
            newInstallment: newInstallment
        });
    }

    handleChangeInstallmentTotalMonths(event: React.ChangeEvent<HTMLSelectElement>) {
        let newInstallment = this.state.newInstallment;
        newInstallment.total_months = Number(event.target.value);
        const d = new Date(newInstallment.year!, newInstallment.month!, 1);
        d.setMonth(d.getMonth() + newInstallment.total_months);
        newInstallment.month_end = d.getUTCMonth();
        newInstallment.year_end = d.getUTCFullYear();
        if (newInstallment.month_end == 0) {
            newInstallment.month_end = 12;
            newInstallment.year_end--;
        }
        this.setState({
            newInstallment: newInstallment
        });
    }

    handleChangeInstallmentMoney(event: React.ChangeEvent<HTMLInputElement>) {
        let newInstallment = this.state.newInstallment;
        newInstallment.money = Number(event.target.value);
        this.setState({
            newInstallment: newInstallment
        });
    }

    handleChangeInstallmentRate(event: React.ChangeEvent<HTMLInputElement>) {
        let newInstallment = this.state.newInstallment;
        newInstallment.installment_rate = Number(event.target.value);
        this.setState({
            newInstallment: newInstallment
        });
    }

    handleChangeInstallmentReason(event: React.ChangeEvent<HTMLInputElement>) {
        let newInstallment = this.state.newInstallment;
        newInstallment.reason = event.target.value;
        this.setState({
            newInstallment: newInstallment
        });
    }

    handleSubmitInstallment: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        const {newInstallment, currentUser} = this.state;
        if (newInstallment.money == null) {
            this.ShowToast("Money can not be empty!");
            return;
        }
        newInstallment.approve = 1;
        newInstallment.approver = currentUser.email;
        PayService.createInstallment(newInstallment).then(
            (result) => {
                if (result['code'] == 0) {
                    window.location.reload();
                } else {
                }
            });
    };

    ShowInstallmentStartMonth = () => {
        let rows = [];
        for (let i = 1; i <= 12; i++) {
            rows.push(<option value={i} selected={this.state.newInstallment.month == i}>{i}</option>);
        }
        return <select className="form-control form-control-line" name="month"
                       id="month"
                       onChange={this.handleChangeInstallmentMonth}>
            {rows}
        </select>;
    };

    ShowInstallmentStartYear = () => {
        let rows = [];
        const y = new Date().getUTCFullYear()
        for (let i = y; i < y + 3; i++) {
            rows.push(<option value={i} selected={this.state.newInstallment.year == i}>{i}</option>);
        }
        return <select className="form-control form-control-line" name="year"
                       id="year"
                       onChange={this.handleChangeInstallmentYear}>
            {rows}
        </select>;
    };

    ShowInstallmentTotalMonths = () => {
        let rows = [];
        for (let i = 3; i <= 36; i++) {
            rows.push(<option value={i} selected={this.state.newInstallment.total_months == i}>{i}</option>);
        }
        return <select className="form-control form-control-line" name="total_months"
                       id="total_months"
                       onChange={this.handleChangeInstallmentTotalMonths}>
            {rows}
        </select>;
    };

    render() {
        const {newPayroll, newInstallment} = this.state;
        return (
            <>
                <div className="card-group">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/dollar.png" alt="assets/images/dollar.png"
                                         width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.CurrencyFormat(this.state.totalSalary)}</h3>
                                    <h6 className="card-subtitle">Total Salary</h6></div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/bills.png"
                                         alt="assets/images/bills.png" width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.CurrencyFormat(this.state.totalBonus)}</h3>
                                    <h6 className="card-subtitle">Total Bonus</h6></div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/discussion.png"
                                         alt="assets/images/discussion.png" width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.state.totalOfficial + this.state.totalIntern}</h3>
                                    <h6 className="card-subtitle">Total Employee</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/excercise.png" alt="assets/images/excercise.png"
                                         width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.state.totalOfficial}/{this.state.totalIntern}</h3>
                                    <h6 className="card-subtitle">Official/Intern</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table m-b-0 toggle-arrow-tiny color-table muted-table">
                        <thead>
                        {this.ShowHeader()}
                        </thead>
                        <tbody>
                        {this.showEmptyPayrollTable()}
                        {this.ShowTable()}
                        </tbody>
                    </table>
                </div>
                <div className="table-responsive">
                    <table className="table m-b-0 toggle-arrow-tiny color-table muted-table">
                        <thead>
                        {this.ShowResignHeader()}
                        </thead>
                        <tbody>
                        {this.showResignTable()}
                        </tbody>
                    </table>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div id="update-payroll-modal" className="modal fade" role="dialog"
                     aria-labelledby="requestLeaveModal" aria-hidden="true">
                    <form className="form-horizontal form-material" onSubmit={this.handleSubmit}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Update Payroll</h4>
                                    <button type="button" className="close" data-dismiss="modal"
                                            aria-hidden="true">×
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <div className="col-md-12 input-group">
                                            <input type="text" value={newPayroll.name}
                                                   className="form-control form-control-line" name="name" readOnly
                                                   id="name"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-12">Type</label>
                                        <div className="col-sm-12 input-group">
                                            <div className="input-group-addon"><i className="ti-view-list"></i>
                                            </div>
                                            <select className="form-control form-control-line" name="employee_type"
                                                    id="employee_type"
                                                    onChange={this.handleChangeEmployeeType}>
                                                <option value="official"
                                                        selected={newPayroll.employee_type == 'official'}>Official
                                                </option>
                                                <option value="probationary"
                                                        selected={newPayroll.employee_type == 'probationary'}>Probationary
                                                </option>
                                                <option value="fresher"
                                                        selected={newPayroll.employee_type == 'fresher'}>Fresher
                                                </option>
                                                <option value="intern"
                                                        selected={newPayroll.employee_type == 'intern'}>Intern
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Salary</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon">$
                                            </div>
                                            <input type="text" value={newPayroll.salary}
                                                   onChange={this.handleChangeSalary}
                                                   className="form-control form-control-line" name="salary"
                                                   id="salary"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Bonus</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon">$
                                            </div>
                                            <input type="text" value={newPayroll.bonus}
                                                   onChange={this.handleChangeBonus}
                                                   className="form-control form-control-line" name="bonus"
                                                   id="bonus"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Salary Rate</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon">%
                                            </div>
                                            <input type="text" value={newPayroll.salary_rate! * 100}
                                                   onChange={this.handleChangeSalaryRate}
                                                   className="form-control form-control-line" name="salary_rate"
                                                   id="salary_rate"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Amercement Rate</label>
                                        <div className="col-md-4 input-group">
                                            <div className="input-group-addon">%
                                            </div>
                                            <input type="text" value={newPayroll.amercement_rate! * 100}
                                                   onChange={this.handleChangeAmercementRate}
                                                   className="form-control form-control-line" name="amercement_rate"
                                                   id="amercement_rate"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-12">Paid Full</label>
                                        <div className="col-sm-12 input-group">
                                            <div className="input-group-addon">$
                                            </div>
                                            <select className="form-control form-control-line" name="paid_full"
                                                    id="paid_full"
                                                    onChange={this.handleChangePaidFull}>
                                                <option value="0"
                                                        selected={newPayroll.paid_full == 0}>No
                                                </option>
                                                <option value="1"
                                                        selected={newPayroll.paid_full == 1}>Yes
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Insurance Salary</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon">$
                                            </div>
                                            <input type="text" value={newPayroll.base_salary}
                                                   onChange={this.handleChangeBaseSalary}
                                                   className="form-control form-control-line" name="base_salary"
                                                   id="base_salary"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-12">Active</label>
                                        <div className="col-sm-12 input-group">
                                            <div className="input-group-addon">$
                                            </div>
                                            <select className="form-control form-control-line" name="active"
                                                    id="active" onChange={this.handleChangeActive}>
                                                <option value="0"
                                                        selected={newPayroll.active == 0}>No
                                                </option>
                                                <option value="1"
                                                        selected={newPayroll.active == 1}>Yes
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Remain Annual Leaves</label>
                                        <div className="col-md-4 input-group">
                                            <div className="input-group-addon">N
                                            </div>
                                            <input type="text" value={newPayroll.annual_leaves}
                                                   onChange={this.handleChangeAnnualLeave}
                                                   className="form-control form-control-line" name="annual_leaves"
                                                   id="annual_leaves"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-6">Apply Date</label>
                                        <div className="col-md-6 input-group">
                                            <div className="input-group-addon">D
                                            </div>
                                            {this.ShowApplyMonth()}
                                            {this.ShowApplyYear()}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit"
                                            className="btn btn-danger waves-effect waves-light">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div id="create-installment-modal" className="modal fade" role="dialog"
                     aria-labelledby="createInstallmentModal" aria-hidden="true">
                    <form className="form-horizontal form-material" onSubmit={this.handleSubmitInstallment}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Installment</h4>
                                    <button type="button" className="close" data-dismiss="modal"
                                            aria-hidden="true">×
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="col-md-12">Email</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon">@
                                            </div>
                                            <input type="text" value={newInstallment.email}
                                                   onChange={this.handleChangeInstallmentEmail}
                                                   className="form-control form-control-line" name="email"
                                                   id="email"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-6">Start</label>
                                        <div className="col-md-6 input-group">
                                            <div className="input-group-addon">D
                                            </div>
                                            {this.ShowInstallmentStartMonth()}
                                            {this.ShowInstallmentStartYear()}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Total Months</label>
                                        <div className="col-md-3 input-group">
                                            {this.ShowInstallmentTotalMonths()}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-6">End</label>
                                        <div className="col-md-6 input-group">
                                            <div className="input-group-addon">D
                                            </div>
                                            <input type="text" value={newInstallment.month_end}
                                                   className="form-control form-control-line" name="month_end" readOnly
                                                   id="month_end"/>
                                            <input type="text" value={newInstallment.year_end}
                                                   className="form-control form-control-line" name="year_end" readOnly
                                                   id="year_end"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Money</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon">$
                                            </div>
                                            <input type="text" value={newInstallment.money}
                                                   onChange={this.handleChangeInstallmentMoney}
                                                   className="form-control form-control-line" name="money"
                                                   id="money"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Interest Rate</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon">%
                                            </div>
                                            <input type="text" value={newInstallment.installment_rate}
                                                   onChange={this.handleChangeInstallmentRate}
                                                   className="form-control form-control-line" name="installment_rate"
                                                   id="installment_rate"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Reason</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon">$
                                            </div>
                                            <input type="text" value={newInstallment.reason}
                                                   onChange={this.handleChangeInstallmentReason}
                                                   className="form-control form-control-line" name="reason"
                                                   id="reason"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit"
                                            className="btn btn-danger waves-effect waves-light">Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div id="resign-modal" className="modal fade" role="dialog"
                     aria-labelledby="resignModal" aria-hidden="true">
                    <form className="form-horizontal form-material" onSubmit={this.handleSubmitResign}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Resign</h4>
                                    <button type="button" className="close" data-dismiss="modal"
                                            aria-hidden="true">×
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <div className="col-md-12 input-group">
                                            <input type="text" value={newPayroll.name}
                                                   className="form-control form-control-line" name="name" readOnly
                                                   id="name"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Date</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon"><i className="ti-time"></i></div>
                                            <input type="date" value={newPayroll.apply_date}
                                                   onChange={this.handleChangeApplyDate}
                                                   className="form-control form-control-line" name="date"
                                                   id="date"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Reason</label>
                                        <div className="col-md-12 input-group">
                                            <div className="input-group-addon">$
                                            </div>
                                            <input type="text" value={newPayroll.description}
                                                   onChange={this.handleChangeReason}
                                                   className="form-control form-control-line" name="reason"
                                                   id="reason"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit"
                                            className="btn btn-danger waves-effect waves-light">Resign
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}