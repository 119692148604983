import React from "react";
import AuthService from "../services/auth.service";
import IUser from "../types/user.type";
import UserService from "../services/user.service";

type Props = {};

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    newUser: IUser,
    updateProfile: number
}

export default class Profile extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser,
            newUser: {
                gender: 'Male'
            } as IUser,
            updateProfile: -1
        };

        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangeBirthday = this.handleChangeBirthday.bind(this);
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleChangeDateOfJoining = this.handleChangeDateOfJoining.bind(this);
        this.handleChangeBankAccount = this.handleChangeBankAccount.bind(this);
        this.handleChangeBankName = this.handleChangeBankName.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleChangeGender = this.handleChangeGender.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                newUser: user,
                isModerator: user.roles == "ROLE_MODERATOR",
                isAdmin: user.roles == "ROLE_ADMIN",
            });
        }
    };

    handleChangePhone(event: React.ChangeEvent<HTMLInputElement>) {
        let newUser = this.state.newUser;
        newUser.phone = event.target.value;
        this.setState({
            newUser: newUser
        });
    }

    handleChangeBirthday(event: React.ChangeEvent<HTMLInputElement>) {
        let newUser = this.state.newUser;
        newUser.birthday = event.target.value;
        this.setState({
            newUser: newUser
        });
    }

    handleChangeAddress(event: React.ChangeEvent<HTMLInputElement>) {
        let newUser = this.state.newUser;
        newUser.address = event.target.value;
        this.setState({
            newUser: newUser
        });
    }

    handleChangeDateOfJoining(event: React.ChangeEvent<HTMLInputElement>) {
        let newUser = this.state.newUser;
        newUser.date_of_joining = event.target.value;
        this.setState({
            newUser: newUser
        });
    }

    handleChangeBankAccount(event: React.ChangeEvent<HTMLInputElement>) {
        let newUser = this.state.newUser;
        newUser.bank_account = event.target.value;
        this.setState({
            newUser: newUser
        });
    }

    handleChangeBankName(event: React.ChangeEvent<HTMLInputElement>) {
        let newUser = this.state.newUser;
        newUser.bank_name = event.target.value;
        this.setState({
            newUser: newUser
        });
    }

    handleChangeRole(event: React.ChangeEvent<HTMLSelectElement>) {
        let newUser = this.state.newUser;
        newUser.title = event.target.value;
        this.setState({
            newUser: newUser
        });
    }

    handleChangeGender(event: React.ChangeEvent<HTMLSelectElement>) {
        let newUser = this.state.newUser;
        newUser.gender = event.target.value;
        this.setState({
            newUser: newUser
        });
    }

    handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        UserService.updateProfile(this.state.newUser).then(
            (result) => {
                if (result['code'] == 0) {
                    this.setState({
                        updateProfile: 1
                    });
                    UserService.getUser(this.state.newUser?.email!).then(
                        (result: IUser) => {
                            if (result.email) {
                                localStorage.setItem("user", JSON.stringify(result));
                            }
                            this.setState({
                                newUser: result,
                                currentUser: result
                            });
                        });
                } else {
                    this.setState({
                        updateProfile: 0
                    })
                }
            });
    };

    showAlert = () => {
        if (this.state.updateProfile == 1) {
            return (
                <div className="alert alert-success alert-rounded"><i className="ti-user"></i>Profile update successful!
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                </div>);
        } else if (this.state.updateProfile == 0) {
            return (
                <div className="alert alert-danger alert-rounded"><i className="ti-user"></i>Profile update failed!
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                </div>);
        }
    }

    render() {
        const {newUser} = this.state;
        return (
            <div className="container-fluid">
                {this.showAlert()}
                <div className="row">
                    <div className="col-lg-4 col-xlg-3 col-md-5">
                        <div className="card">
                            <img className="card-img" src="assets/images/background/socialbg.jpg" alt="Card image"/>
                            <div className="card-img-overlay card-inverse social-profile d-flex ">
                                <div className="align-self-center"><img src={newUser.picture} className="img-circle"
                                                                        width="100"/>
                                    <h4 className="card-title">{newUser.name}</h4>
                                    <h6 className="card-subtitle">{newUser.title}</h6>
                                    <h6 className="card-subtitle">{newUser.email}</h6>
                                    <p className="text-white">The best way to predict the future is to create it.</p>
                                    <small>– Abraham Lincoln -</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-xlg-9 col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <form className="form-horizontal form-material" onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label className="col-md-12">Full Name</label>
                                        <div className="col-md-12">
                                            <input type="text" value={newUser.name}
                                                   className="form-control form-control-line" name="full_name"
                                                   id="full_name" readOnly/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="example-email" className="col-md-12">Email</label>
                                        <div className="col-md-12">
                                            <input type="email" value={newUser.email}
                                                   className="form-control form-control-line" name="email" id="email"
                                                   readOnly/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-12">Role</label>
                                        <div className="col-sm-12">
                                            <select className="form-control form-control-line" name="title" id="title"
                                                    onChange={this.handleChangeRole}>
                                                <option value="CEO" selected={newUser.title == 'CEO'}>CEO</option>
                                                <option value="BOD" selected={newUser.title == 'BOD'}>BOD</option>
                                                <option value="Leader" selected={newUser.title == 'Leader'}>Leader
                                                </option>
                                                <option value="Developer"
                                                        selected={newUser.title == 'Developer'}>Developer
                                                </option>
                                                <option value="Artist" selected={newUser.title == 'Artist'}>Artist
                                                </option>
                                                <option value="Game Designer"
                                                        selected={newUser.title == 'Game Designer'}>Game Designer
                                                </option>
                                                <option value="Marketing"
                                                        selected={newUser.title == 'Marketing'}>Marketing
                                                </option>
                                                <option value="Business Analyst"
                                                        selected={newUser.title == 'Business Analyst'}>Business Analyst
                                                </option>
                                                <option value="Tester" selected={newUser.title == 'Tester'}>Tester
                                                </option>
                                                <option value="Animator/Fx"
                                                        selected={newUser.title == 'Animator/Fx'}>Animator/Fx
                                                </option>
                                                <option value="Sale" selected={newUser.title == 'Sale'}>Sale</option>
                                                <option value="HR/AdminLeave"
                                                        selected={newUser.title == 'HR/AdminLeave'}>HR/AdminLeave
                                                </option>
                                                <option value="Accountant"
                                                        selected={newUser.title == 'Accountant'}>Accountant
                                                </option>
                                                <option value="Other" selected={newUser.title == 'Other'}>Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Phone No</label>
                                        <div className="col-md-12">
                                            <input type="text" value={newUser.phone} onChange={this.handleChangePhone}
                                                   className="form-control form-control-line" name="phone" id="phone"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Birthday</label>
                                        <div className="col-md-12">
                                            <input type="date" value={newUser.birthday}
                                                   onChange={this.handleChangeBirthday}
                                                   className="form-control form-control-line" name="birthday"
                                                   id="birthday"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-sm-12">Gender</label>
                                        <div className="col-sm-12">
                                            <select className="form-control form-control-line" name="gender" id="gender"
                                                    onChange={this.handleChangeGender}>
                                                <option value="Male" selected={newUser.gender == 'Male'}>Male</option>
                                                <option value="Female" selected={newUser.gender == 'Female'}>Female
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Address</label>
                                        <div className="col-md-12">
                                            <input type="text" value={newUser.address}
                                                   onChange={this.handleChangeAddress}
                                                   className="form-control form-control-line" name="address"
                                                   id="address"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Date Of Joining</label>
                                        <div className="col-md-12">
                                            <input type="date" value={newUser.date_of_joining}
                                                   onChange={this.handleChangeDateOfJoining}
                                                   className="form-control form-control-line" name="date_of_joining"
                                                   id="date_of_joining"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Bank Account</label>
                                        <div className="col-md-12">
                                            <input type="text" value={newUser.bank_account}
                                                   onChange={this.handleChangeBankAccount}
                                                   className="form-control form-control-line" name="bank_account"
                                                   id="bank_account"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-md-12">Bank Name</label>
                                        <div className="col-md-12">
                                            <input type="text" value={newUser.bank_name}
                                                   onChange={this.handleChangeBankName}
                                                   className="form-control form-control-line" name="bank_name"
                                                   id="bank_name"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <button className="btn btn-success" type="submit">Update Profile</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}