import AuthService from "../services/auth.service";

interface IConfig {
    flexible_checkin?: string,
    start_date?: string,
    end_date?: string,
    morning_time?: string[],
    lunch_time?: string[],
    afternoon_time?: string[],
    saturday_off?: number,
    working_hour?: string[]
}

interface ITimeConfig {
    active: string,
    time_configs: string[]
}

interface Dictionary<T> {
    [Key: string]: T;
}

class WTConfig {
    config: IConfig;
    private morningTime: Map<number, number[]>;
    private lunchTime: Map<number, number[]>;
    private afternoonTime: Map<number, number[]>;
    private workingHour: Map<number, number>;

    constructor(config: IConfig) {
        this.config = config;
        this.morningTime = new Map<number, number[]>();
        Object.entries(this.config?.morning_time!).forEach(([key, value]) => {
            this.morningTime.set(parseInt(key), this.getTime(value));
        });
        this.lunchTime = new Map<number, number[]>();
        Object.entries(this.config?.lunch_time!).forEach(([key, value]) => {
            this.lunchTime.set(parseInt(key), this.getTime(value));
        });
        this.afternoonTime = new Map<number, number[]>();
        Object.entries(this.config?.afternoon_time!).forEach(([key, value]) => {
            this.afternoonTime.set(parseInt(key), this.getTime(value));
        });
        this.workingHour = new Map<number, number>();
        Object.entries(this.config?.working_hour!).forEach(([key, value]) => {
            this.workingHour.set(parseInt(key), parseInt(value));
        });
    }

    getFlexibleCheckin(): number[] {
        return this.getTime(this.config?.flexible_checkin);
    }

    getMorningTime(w: number): number[] {
        if (this.morningTime.has(w))
            return this.morningTime.get(w)!;
        return this.morningTime.get(-1)!;
    }

    getLunchTime(w: number): number[] {
        if (this.lunchTime.has(w))
            return this.lunchTime.get(w)!;
        return this.lunchTime.get(-1)!;
    }

    getAfternoonTime(w: number): number[] {
        if (this.afternoonTime.has(w))
            return this.afternoonTime.get(w)!;
        return this.afternoonTime.get(-1)!;
    }

    getWorkingHour(w: number): number {
        if (this.workingHour.has(w))
            return this.workingHour.get(w)!;
        return this.workingHour.get(-1)!;
    }

    IsSaturdayOff(): boolean {
        return this.config?.saturday_off == -1;
    }

    getTime(time?: string): number[] {
        if (!time) return [];
        let strings = time.split(':');
        return strings.map(function (x) {
            return parseInt(x);
        });
    }
}

type Callback = (n: boolean) => void;

class WorkingTime {
    timeConfig: ITimeConfig;
    workingConfig: Dictionary<WTConfig> = {};
    callback: Callback;

    constructor() {
        AuthService.getConfig().then(
            (result) => {
                this.timeConfig = result['config'];
                for (let config of this.timeConfig.time_configs) {
                    this.workingConfig[config] = new WTConfig(result[config]);
                }
                this.callback(true);
            }
        );
    }

    init(callback: Callback) {
        this.callback = callback;
    }

    getCurrentConfig(): WTConfig {
        return this.workingConfig[this.timeConfig?.active];
    }

    getConfig(date: Date): WTConfig {
        for (let configId of this.timeConfig?.time_configs) {
            let config = this.workingConfig[configId];
            if (!config.config?.start_date) continue;
            let startDate = new Date(config.config?.start_date + " 00:00:00");
            if (!config.config?.end_date) {
                if (startDate <= date) return config;
                continue;
            }
            let endDate = new Date(config.config?.end_date + " 23:59:59");
            if (startDate <= date && date <= endDate) return config;
        }
        return this.getCurrentConfig();
    }

    getAllConfig(): Dictionary<WTConfig> {
        return this.workingConfig;
    }
}

export default new WorkingTime();