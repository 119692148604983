import React from "react";
import AuthService from "../services/auth.service";

export default class Header extends React.Component {
    logout = () => {
        AuthService.logout();
        window.location.reload();
        console.log("header logout");
    };

    render() {
        return (
            <header className="topbar">
                <nav className="navbar top-navbar navbar-expand-md navbar-light">
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/">
                            <b>
                                <img src="assets/images/logo3.png" alt="homepage" className="dark-logo" height="60"/>
                                <img src="assets/images/logo3.png" alt="homepage" className="light-logo" height="60"/>
                            </b>
                            <span>
                            <img src="assets/images/logo-text.png" alt="homepage" className="dark-logo" height="42"/>
                            <img src="assets/images/logo-text.png" className="light-logo" alt="homepage"
                                 height="42"/></span>
                        </a>
                    </div>
                    <div className="navbar-collapse">
                        <ul className="navbar-nav mr-auto mt-md-0">
                            <li className="nav-item"><a
                                className="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark"
                                href="javascript:void(0)"><i className="mdi mdi-menu"></i></a></li>
                            <li className="nav-item m-l-10"><a
                                className="nav-link sidebartoggler hidden-sm-down text-muted waves-effect waves-dark"
                                href="javascript:void(0)"><i className="ti-menu"></i></a></li>
                            <li className="nav-item">
                                <div className="div-center"><h3 className="text-white text-center"></h3>
                                </div>
                            </li>
                        </ul>

                        <ul className="navbar-nav my-lg-0">
                            <div>
                                <button className="btn btn-sm btn-default"
                                        onClick={() => this.logout()}>Logout
                                </button>
                            </div>
                        </ul>
                    </div>
                </nav>
            </header>
        )
    }
}

