import React from "react";
import AuthService from "../services/auth.service";
import IUser from "../types/user.type";
import IPayroll from "../types/payroll.type";
import UserPayslip from "../component/user.payslip";
import UserService from "../services/user.service";
import {useNavigate} from "react-router";

type Props = {};

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    queryUser: IUser,
    selectMonth: Date,
    date: Date,
}

export default class Payslip extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser,
            queryUser: {} as IUser,
            selectMonth: this.GetStartMonth({}),
            date: new Date(),
        };
    }

    componentDidMount = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const email = queryParams.get("email");
        const user = AuthService.getCurrentUser();
        if (email != null && email != "" && email != user?.email) {
            if (user.roles != "ROLE_ADMIN") {
                window.location.replace("/");
                return;
            }
            UserService.getUser(email!).then((u: IUser) => {
                this.setState({
                    currentUser: user,
                    queryUser: u,
                    selectMonth: this.GetStartMonth(u),
                    isModerator: user.roles == "ROLE_MODERATOR",
                    isAdmin: user.roles == "ROLE_ADMIN",
                });
            });
        } else if (user) {
            this.setState({
                currentUser: user,
                queryUser: user,
                selectMonth: this.GetStartMonth(user),
                isModerator: user.roles == "ROLE_MODERATOR",
                isAdmin: user.roles == "ROLE_ADMIN",
            });
        }
    };

    DateHeader = () => {
        let {selectMonth} = this.state;
        return (
            selectMonth.toLocaleDateString("en-US", {month: 'long', year: 'numeric'})
        );
    };

    GetThisMonth = () => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getDate() > 26 ? (now.getMonth() + 1) : now.getMonth(), 1);
    };

    GetStartMonth = (queryUser: IUser) => {
        if (queryUser != null && queryUser.resign_date != null) {
            const resign = new Date(queryUser.resign_date.replace(/-/g, "/"));
            resign.setDate(1);
            return resign;
        }
        return this.GetThisMonth();
    };

    btn_prev_month = () => {
        const {selectMonth, queryUser} = this.state;
        if (selectMonth.getFullYear() == 2022 && selectMonth.getMonth() <= 3)
            return;
        if (queryUser.date_of_joining != null) {
            const join = new Date(queryUser.date_of_joining.replace(/-/g, "/"));
            if (join.getDate() > 25)
                join.setMonth(join.getMonth() + 1);
            if (join >= selectMonth)
                return;
        }
        let a = new Date(selectMonth.getFullYear(), selectMonth.getMonth() - 1, 1);
        this.setState({
            selectMonth: a
        });
        this.DateHeader();
    };

    btn_next_month = () => {
        const {selectMonth, queryUser} = this.state;
        if (selectMonth >= this.GetThisMonth())
            return;
        if (queryUser.resign_date != null) {
            const resign = new Date(queryUser.resign_date.replace(/-/g, "/"));
            resign.setMonth(resign.getMonth() - 1);
            resign.setDate(1);
            if (selectMonth > resign)
                return;
        }
        let a = new Date(selectMonth.getFullYear(), selectMonth.getMonth() + 1, 1);
        this.setState({
            selectMonth: a
        });
        this.DateHeader();
    };

    ShowButton = () => {
        /*const {curAttendance, queryAttendanceDone} = this.state;
        if (!queryAttendanceDone) {
            return <></>;
        }
        if (curAttendance == null) {
            return (<button type="button" onClick={this.checkin}
                            className="m-t-10 m-b-20 waves-effect waves-dark btn btn-primary btn-md btn-rounded">Check
                In</button>);
        } else if (curAttendance.clockout == null || curAttendance.clockout.trim() == "" || curAttendance.clockout.trim().length == 0) {
            return (<button type="button" data-toggle="modal" data-target="#checkout-modal"
                            className="m-t-10 m-b-20 waves-effect waves-dark btn btn-danger btn-md btn-rounded">Check
                Out</button>);
        } else {
            return (<span className="m-t-10 m-b-20 btn-md label label-success label-rounded">Thankyou</span>);
        }*/
        return (<></>);
    };

    render() {
        const {queryUser, selectMonth} = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-xlg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="fc-toolbar fc-header-toolbar">
                                    <div className="fc-left">
                                        <button type="button"
                                                className="fc-prev-button fc-button fc-state-default fc-corner-left"
                                                onClick={this.btn_prev_month}><span
                                            className="mdi mdi-chevron-left"></span></button>
                                    </div>
                                    <div className="fc-center">
                                        <h2 id="date_header" className="text-info2">{this.DateHeader()}</h2>
                                    </div>
                                    <div className="fc-right">
                                        <button type="button"
                                                className="fc-next-button fc-button fc-state-default fc-corner-right"
                                                onClick={this.btn_next_month}><span
                                            className="mdi mdi-chevron-right"></span></button>
                                    </div>
                                    <div className="fc-clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <UserPayslip selectMonth={selectMonth} queryUser={queryUser}/>
                </div>
            </div>
        )
    }
}