class DateTimeUtil {
    getMonth(month: number, year: number) {
        if (month == 0) {
            return 12;
        }
        return month;
    }

    getYear(month: number, year: number) {
        if (month == 0) {
            return year - 1;
        }
        return year;
    }
}

export default new DateTimeUtil();