import axios from 'axios';
import IEvent from "../types/event.type";
import IUser from "../types/user.type";
import {Config} from "../cfg/Config";
import DateTimeUtil from "../common/DateTimeUtil";

const qs = require('qs');

class EventService {
    get() {
        return axios
            .post(Config.API_URL + "event.php", qs.stringify({
                action: "get"
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    create(currentUser: IUser, currentEvent: IEvent) {
        return axios
            .post(Config.API_URL + "event.php", qs.stringify({
                action: "create",
                owner: currentUser.email,
                title: currentEvent.title,
                description: currentEvent.description,
                users: currentEvent.users,
                location: currentEvent.location,
                type: currentEvent.type,
                is_company_event: currentEvent.is_company_event,
                is_paid_holiday: currentEvent.is_paid_holiday,
                is_day_off: currentEvent.is_day_off,
                start_str: currentEvent.start_str,
                end_str: currentEvent.end_str,
                all_day: currentEvent.all_day,
                repeat_period: currentEvent.repeat_period,
                color: currentEvent.color
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    update(id: number, title: string, description: string, users: string, location: string, type: string, host: string, is_company_event: number, is_paid_holiday: number, is_day_off: number, start_str: string, end_str: string, all_day: number, repeat_period: string, color: string) {
        return axios
            .post(Config.API_URL + "event.php", qs.stringify({
                action: "update",
                id: id,
                title: title,
                description: description,
                users: users,
                location: location,
                type: type,
                host: host,
                is_company_event: is_company_event,
                is_paid_holiday: is_paid_holiday,
                is_day_off: is_day_off,
                start_str: start_str,
                end_str: end_str,
                all_day: all_day,
                repeat_period: repeat_period,
                color: color,
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    delete(id: number) {
        return axios
            .post(Config.API_URL + "event.php", qs.stringify({
                action: "delete",
                id: id,
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getEvents(email: string, month: number, year: number) {
        let fromDate = new Date(year, month - 1, 1);
        let toDate = new Date(year, month, 31);
        return axios
            .post(Config.API_URL + "event.php", qs.stringify({
                action: "multi_range",
                email: email,
                from_day: 1,
                from_month: ("0" + DateTimeUtil.getMonth(fromDate.getMonth(), fromDate.getFullYear())).slice(-2),
                from_year: DateTimeUtil.getYear(fromDate.getMonth(), fromDate.getFullYear()),
                to_day: 31,
                to_month: ("0" + DateTimeUtil.getMonth(toDate.getMonth(), toDate.getFullYear())).slice(-2),
                to_year: DateTimeUtil.getYear(toDate.getMonth(), toDate.getFullYear())
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getAllEventsByDay(day: number, month: number, year: number) {
        let fromDate = new Date(year, month, day);
        let toDate = new Date(year, month, day);
        return axios
            .post(Config.API_URL + "event.php", qs.stringify({
                action: "all_range",
                from_day: ("0" + fromDate.getDate()).slice(-2),
                from_month: ("0" + DateTimeUtil.getMonth(fromDate.getMonth(), fromDate.getFullYear())).slice(-2),
                from_year: DateTimeUtil.getYear(fromDate.getMonth(), fromDate.getFullYear()),
                to_day: ("0" + toDate.getDate()).slice(-2),
                to_month: ("0" + DateTimeUtil.getMonth(toDate.getMonth(), toDate.getFullYear())).slice(-2),
                to_year: DateTimeUtil.getYear(toDate.getMonth(), toDate.getFullYear())
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getAllEvents(month: number, year: number) {
        let fromDate = new Date(year, month - 3, 1);
        let toDate = new Date(year, month + 2, 31);
        return axios
            .post(Config.API_URL + "event.php", qs.stringify({
                action: "all_range",
                from_day: 1,
                from_month: ("0" + DateTimeUtil.getMonth(fromDate.getMonth(), fromDate.getFullYear())).slice(-2),
                from_year: DateTimeUtil.getYear(fromDate.getMonth(), fromDate.getFullYear()),
                to_day: 31,
                to_month: ("0" + DateTimeUtil.getMonth(toDate.getMonth(), toDate.getFullYear())).slice(-2),
                to_year: DateTimeUtil.getYear(toDate.getMonth(), toDate.getFullYear())
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }
}

export default new EventService();