import axios from 'axios';
import {Config} from "../cfg/Config";
import DateTimeUtil from "../common/DateTimeUtil";

const qs = require('qs');

class AttendanceService {
    checkIn(email: string | undefined, isAuto: boolean) {
        return axios
            .post(Config.API_URL + "attendance.php", qs.stringify({
                action: "checkin",
                email: email,
                isAuto: isAuto
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    checkOut(email: string | undefined, workinghr: number) {
        return axios
            .post(Config.API_URL + "attendance.php", qs.stringify({
                action: "checkout",
                email: email,
                workinghr: workinghr,
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getAttendance(email: string | undefined, day: number, month: number, year: number) {
        return axios
            .post(Config.API_URL + "attendance.php", qs.stringify({
                action: "one",
                email: email,
                day: day,
                month: month,
                year: year
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getAttendances(email: string, month: number, year: number) {
        let fromDate = new Date(year, month - 1, 26);
        let toDate = new Date(year, month, 25);
        return axios
            .post(Config.API_URL + "attendance.php", qs.stringify({
                action: "multi_range",
                email: email,
                from_day: 26,
                from_month: ("0" + DateTimeUtil.getMonth(fromDate.getMonth(), fromDate.getFullYear())).slice(-2),
                from_year: DateTimeUtil.getYear(fromDate.getMonth(), fromDate.getFullYear()),
                to_day: 25,
                to_month: ("0" + DateTimeUtil.getMonth(toDate.getMonth(), toDate.getFullYear())).slice(-2),
                to_year: DateTimeUtil.getYear(toDate.getMonth(), toDate.getFullYear())
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getAllAttendancesByDay(day: number, month: number, year: number) {
        return axios
            .post(Config.API_URL + "attendance.php", qs.stringify({
                action: "all_by_day",
                day: day,
                month: month,
                year: year
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getAllAttendances(month: number, year: number) {
        let fromDate = new Date(year, month - 1, 26);
        let toDate = new Date(year, month, 25);
        return axios
            .post(Config.API_URL + "attendance.php", qs.stringify({
                action: "all_range",
                from_day: 26,
                from_month: ("0" + DateTimeUtil.getMonth(fromDate.getMonth(), fromDate.getFullYear())).slice(-2),
                from_year: DateTimeUtil.getYear(fromDate.getMonth(), fromDate.getFullYear()),
                to_day: 25,
                to_month: ("0" + DateTimeUtil.getMonth(toDate.getMonth(), toDate.getFullYear())).slice(-2),
                to_year: DateTimeUtil.getYear(toDate.getMonth(), toDate.getFullYear())
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }
}

export default new AttendanceService();