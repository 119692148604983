import React from "react";
import IUser from "../types/user.type";
import AuthService from "../services/auth.service";
import PayService from "../services/pay.service";
import IPayslip from "../types/payslip.type";
import IDeduction from "../types/deduction.type";
import moment from "moment";

type Props = {
    selectMonth: Date,
    queryUser: IUser
}

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    queryUser: IUser,
    selectMonth: Date,
    payslip: IPayslip,
    showMoney: boolean
}

export default class UserPayslip extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser,
            selectMonth: this.props.selectMonth,
            queryUser: this.props.queryUser,
            payslip: {} as IPayslip,
            showMoney: false
        };

        this.handleChangeShowSalary = this.handleChangeShowSalary.bind(this);
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                isModerator: user.roles?.includes("ROLE_MODERATOR"),
                isAdmin: user.roles?.includes("ROLE_ADMIN"),
            });
        }
    };

    componentDidUpdate = () => {
        if (this.state.selectMonth != this.props.selectMonth || this.state.queryUser != this.props.queryUser) {
            this.setState({
                selectMonth: this.props.selectMonth,
                queryUser: this.props.queryUser,
                payslip: {} as IPayslip
            });
            this.updateData();
        }
    };

    updateData() {
        if (this.props.queryUser?.email == null)
            return;
        PayService.getPayslip(this.props.queryUser?.email!, this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
            (a: IPayslip) => {
                this.setState({
                    payslip: a
                });
            }
        );
    };

    CreateLink = (isAdmin: boolean, email: string, picture: string) => {
        if (isAdmin) {
            return (<a href={"/user?email=" + email}><img src={picture} alt="user" className="img-circle"
                                                          width="150"/></a>);
        } else {
            return (<img src={picture} alt="user" className="img-circle" width="150"/>);
        }
    };

    handleChangeShowSalary(event: React.ChangeEvent<HTMLInputElement>) {
        const showMoney = !this.state.showMoney;
        this.setState({
            showMoney: showMoney
        });
    }

    CurrencyFormat = (num: number) => {
        return new Intl.NumberFormat('vi-VN', {style: 'currency', currency: 'VND'}).format(num);
    };

    ShowAllOthersBonuses = () => {
        const {payslip, showMoney} = this.state;
        if (payslip?.bonuses == null)
            return <></>;
        return payslip?.bonuses!.map((deduction, index) => (
            <div className="d-flex m-l-20">
                <div className="align-self-center">({index + 1}) {deduction.reason}</div>
                <div className="ml-auto align-self-center">
                    <h6 className="text-muted">{!showMoney ? "******" : this.CurrencyFormat(deduction.money!)}</h6>
                </div>
            </div>
        ))
    };

    ShowAllOthersDeductions = () => {
        const {payslip, showMoney} = this.state;
        if (payslip?.deductions == null)
            return <></>;
        return payslip?.deductions!.map((deduction, index) => (
            <div className="d-flex m-l-20">
                <div className="align-self-center">({index + 1}) {deduction.reason}</div>
                <div className="ml-auto align-self-center">
                    <h6 className="text-muted">{!showMoney ? "******" : this.CurrencyFormat(deduction.money!)}</h6>
                </div>
            </div>
        ))
    };

    ShowAllAmercementReason = () => {
        const {payslip, showMoney} = this.state;
        if (payslip?.amercement_reasons == null)
            return <></>;
        return payslip?.amercement_reasons!.map((amercement, index) => (
            <div className="d-flex m-l-20">
                <div className="align-self-center">- {moment(amercement.date).format("ddd, MMM DD")}: {amercement.reason}</div>
                <div className="ml-auto align-self-center">
                    <h6 className="text-muted">{amercement.count!}</h6>
                </div>
            </div>
        ))
    };

    capitalize = (s: string) => {
        return s && s[0].toUpperCase() + s.slice(1);
    };

    GetInstallmentMonths(att: IDeduction) {
        const months = (Number(att.year_end!) - this.props.selectMonth.getFullYear()) * 12 + Number(att.month_end!) - this.props.selectMonth.getMonth();
        return Math.max(Math.min(months, Number(att.total_months!)), 0);
    };

    GetInstallmentThisMonth() {
        const {payslip} = this.state;
        if (payslip?.installments == null)
            return 0;
        let total = 0;
        payslip?.installments.map((deduction) => (
            total += (new Date(deduction.year!, deduction.month! - 1, 1) <= this.props.selectMonth && this.props.selectMonth < new Date(deduction.year_end!, deduction.month_end!, 1)) ? Number(deduction.money! / deduction.total_months! * (1 + deduction.installment_rate! / 100)) : 0
        ));
        return total;
    };

    ShowInstallmentTable() {
        const {payslip, showMoney} = this.state;
        if (payslip?.installments != null && payslip?.installments.length > 0) {
            return payslip?.installments.map((att) => (
                <tr key={att.id}>
                    <td>{att.reason}</td>
                    <td>{!showMoney ? "******" : this.CurrencyFormat(Number(att.money!))}</td>
                    <td>{!showMoney ? "******" : this.CurrencyFormat(Number(att.money! / att.total_months! * (1 + att.installment_rate! / 100) * this.GetInstallmentMonths(att)))}</td>
                    <td>{att.month}/{att.year}</td>
                    <td>{att.month_end}/{att.year_end}</td>
                </tr>
            ))
        }
    };

    render() {
        const {payslip, showMoney, isAdmin} = this.state;
        return (
            <>
                <div className="col-lg-4 col-xlg-4 col-md-4">
                    <div className="card">
                        <div className="m-t-30 text-center">
                            {this.CreateLink(isAdmin, payslip?.user?.email!, payslip?.user?.picture!)}
                            <h4 className="card-title m-t-10">{payslip?.user?.name!}</h4>
                            <h5 className="card-subtitle">{payslip?.user?.title!} {payslip?.employee_type != null && payslip?.employee_type != 'official' ? (' - ' + this.capitalize(payslip?.employee_type!)) : ''}</h5>
                            <input name="show_money" type="checkbox" id="show_money"
                                   onChange={this.handleChangeShowSalary}
                                   className="filled-in chk-col-cyan"/>
                            <label htmlFor="show_money">Show Salary</label>
                        </div>
                        <div>
                            <hr/>
                        </div>
                        <div className="p-20">
                            <div className="d-flex">
                                <div className="align-self-center text-bold">Amercement</div>
                                <div className="ml-auto align-self-center">
                                    <h4 className="text-bold-orange">{this.CurrencyFormat(payslip?.amercement!)}</h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div>Check In Late</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{payslip?.check_in_late!} min</h4></div>
                            </div>
                            <div className="d-flex">
                                <div className="align-self-center">Request Declined</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{Number(payslip?.request_decline_amercement!)}</h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="align-self-center">Other Amercement</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{Number(payslip?.forgot_check_in_out!) + Number(payslip?.check_out_early_amercement!) + Number(payslip?.absent_day!)}</h4>
                                </div>
                            </div>
                            {this.ShowAllAmercementReason()}
                        </div>
                        <div>
                            <hr/>
                        </div>
                        <div className="p-20">
                            <div className="d-flex">
                                <div>(1) Working day plan</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{payslip?.total_working_days!}</h4></div>
                            </div>
                            <div className="d-flex">
                                <div className="align-self-center">(2) Paid working day</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{Number(payslip?.paid_working_days!) + Number(payslip?.paid_leave!) + Number(payslip?.annual_leave!)}</h4>
                                </div>
                            </div>
                            <div className="d-flex m-l-20">
                                <div className="align-self-center">(2.1) Actual working day</div>
                                <div className="ml-auto align-self-center">
                                    <h6 className="text-muted">{payslip?.paid_working_days!}</h6></div>
                            </div>
                            <div className="d-flex m-l-20">
                                <div className="align-self-center">(2.2) Paid leave</div>
                                <div className="ml-auto align-self-center">
                                    <h6 className="text-muted">{payslip?.paid_leave!}</h6></div>
                            </div>
                            <div className="d-flex m-l-20">
                                <div className="align-self-center">(2.3) Unpaid leave</div>
                                <div className="ml-auto align-self-center">
                                    <h6 className="text-muted">{payslip?.leave_day!}</h6></div>
                            </div>
                            {payslip.employee_type == 'official' && <div className="d-flex m-l-20">
                                <div className="align-self-center">(2.4) Annual leave</div>
                                <div className="ml-auto align-self-center">
                                    <h6 className="text-muted">{payslip?.annual_leave!}</h6></div>
                            </div>}
                            {payslip.employee_type == 'official' && <div className="d-flex">
                                <div>(3) Remain Annual Leaves</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{payslip?.annual_leave_remain!}</h4></div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-xlg-4 col-md-4">
                    <div className="card">
                        <div className="p-20">
                            <div className="d-flex">
                                <div className="align-self-center text-bold">A. Earnings</div>
                                <div className="ml-auto align-self-center">
                                    <h4 className="text-bold-green">{!showMoney ? "******" : this.CurrencyFormat(payslip?.earnings!)}</h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div>Base Salary</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{!showMoney ? "******" : this.CurrencyFormat(payslip?.salary!)}</h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="align-self-center">Bonus</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{!showMoney ? "******" : this.CurrencyFormat(payslip?.bonus!)}</h4></div>
                            </div>
                            {this.ShowAllOthersBonuses()}
                            <div className="d-flex">
                                <div>Paid working day</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{Number(payslip?.paid_working_days!) + Number(payslip?.paid_leave!) + Number(payslip?.annual_leave!)} / {payslip?.total_working_days!}</h4>
                                </div>
                            </div>
                        </div>
                        <div>
                            <hr/>
                        </div>
                        <div className="p-20">
                            <div className="d-flex">
                                <div className="align-self-center text-bold">B. Deductions</div>
                                <div className="ml-auto align-self-center">
                                    <h4 className="text-bold-orange">{!showMoney ? "******" : this.CurrencyFormat(Number(payslip?.amercement!) + Number(payslip?.others_deduction!) + Number(payslip?.social_insurance!) + Number(payslip?.health_insurance!) + Number(payslip?.unemployment_insurance!))}</h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div>Social Insurance (8%)</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{!showMoney ? "******" : this.CurrencyFormat(payslip?.social_insurance!)}</h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div>Health Insurance (1.5%)</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{!showMoney ? "******" : this.CurrencyFormat(payslip?.health_insurance!)}</h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div>Unemployment Insurance (1%)</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{!showMoney ? "******" : this.CurrencyFormat(payslip?.unemployment_insurance!)}</h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="align-self-center">Personal Income Tax</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{!showMoney ? "******" : this.CurrencyFormat(0)}</h4></div>
                            </div>
                            <div className="d-flex">
                                <div>Amercement</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{!showMoney ? "******" : this.CurrencyFormat(payslip?.amercement!)}</h4>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="align-self-center">Other Deduction</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{!showMoney ? "******" : this.CurrencyFormat(payslip?.others_deduction!)}</h4>
                                </div>
                            </div>
                            {this.ShowAllOthersDeductions()}
                        </div>
                        <div>
                            <hr/>
                        </div>
                        <div className="p-20">
                            <div className="d-flex">
                                <div className="align-self-center text-bold">NET Income</div>
                                <div className="ml-auto align-self-center">
                                    <h4 className="text-bold-green">{!showMoney ? "******" : this.CurrencyFormat(Number(payslip?.earnings!) - Number(payslip?.amercement!) - Number(payslip?.others_deduction!) - (Number(payslip?.social_insurance!) + Number(payslip?.health_insurance!) + Number(payslip?.unemployment_insurance!)))}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-xlg-4 col-md-4">
                    {payslip?.installments != null && payslip?.installments!.length > 0 &&
                    <div className="card">
                        <div className="table-responsive">
                            <table className="table m-b-0 toggle-arrow-tiny color-table muted-table">
                                <thead>
                                <tr>
                                    <th className="footable-visible footable-sortable"> Installment</th>
                                    <th className="footable-visible footable-sortable"> Amount</th>
                                    <th className="footable-visible footable-sortable"> Left</th>
                                    <th className="footable-visible footable-sortable"> Start</th>
                                    <th className="footable-visible footable-sortable"> End</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.ShowInstallmentTable()}
                                <tr key="-1">
                                    <td>
                                        <div className="align-self-center text-bold">This month</div>
                                    </td>
                                    <td colSpan={4}>{!showMoney ? "******" : this.CurrencyFormat(Number(this.GetInstallmentThisMonth()))}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>}
                    <div className="card">
                        <div className="p-20">
                            <div className="d-flex">
                                <div>Bank Name</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{payslip?.user?.bank_name!}</h4></div>
                            </div>
                            <div className="d-flex">
                                <div className="align-self-center">Bank Account</div>
                                <div className="ml-auto align-self-center">
                                    <h4>{payslip?.user?.bank_account!}</h4></div>
                            </div>
                            <div className="d-flex">
                                <div className="m-t-10 text-center">
                                    <a href={"https://img.vietqr.io/image/" + payslip?.user?.bank_name + "-" + payslip?.user?.bank_account + "-print.png?accountName=" + payslip?.user?.name}
                                       target="_blank">
                                        <img width="150" height="150"
                                             src={"https://img.vietqr.io/image/" + payslip?.user?.bank_name + "-" + payslip?.user?.bank_account + "-qr_only.png"}
                                             alt="qr_code"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}