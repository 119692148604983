import axios from "axios";
import IUser from "../types/user.type";
import {Config} from "../cfg/Config";

const qs = require('qs');

class AuthService {
    login(userInfo: IUser) {
        return axios
            .post(Config.API_URL + "signin.php", qs.stringify(userInfo), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                if (response.data.access_token) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                    return response.data;
                }
                return null;
            });
    }

    logout() {
        localStorage.removeItem("user");
    }

    getCurrentUser() {
        const userStr = localStorage.getItem("user");
        if (userStr) {
            const user = JSON.parse(userStr);
            // console.log(user);
            if (user["access_token"] != null && user["access_token"] != "")
                return user;
        }
        return null;
    }

    getIp() {
        return axios
            .post(Config.API_URL + "tool.php", qs.stringify({
                action: "ip",
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    addNewIp(newIP: string) {
        return axios
            .post(Config.API_URL + "tool.php", qs.stringify({
                action: "add_ip",
                ip: newIP,
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getConfig() {
        return axios
            .post(Config.API_URL + "tool.php", qs.stringify({
                action: "config",
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                }
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }
}

export default new AuthService();