import axios from 'axios';
import IPayroll from "../types/payroll.type";
import IDeduction from "../types/deduction.type";
import {Config} from "../cfg/Config";
import DateTimeUtil from "../common/DateTimeUtil";
import authHeader from "./auth.header";

const qs = require('qs');

class PayService {
    getPayroll(email: string) {
        return axios
            .post(Config.API_URL + "pay.php", qs.stringify({
                action: "payroll",
                email: email
            }), {
                headers: authHeader()
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getAllPayrolls() {
        return axios
            .post(Config.API_URL + "pay.php", qs.stringify({
                action: "all_payroll"
            }), {
                headers: authHeader()
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    createPayroll(payroll: IPayroll) {
        return axios
            .post(Config.API_URL + "pay.php", qs.stringify({
                action: "create_payroll",
                user_id: payroll.user_id,
                email: payroll.email,
                salary: payroll.salary,
                base_salary: payroll.base_salary,
                bonus: payroll.bonus,
                salary_rate: payroll.salary_rate,
                amercement_rate: payroll.amercement_rate,
                employee_type: payroll.employee_type,
                paid_full: payroll.paid_full,
                active: payroll.active,
                apply_month: payroll.apply_month,
                apply_year: payroll.apply_year,
                approver: payroll.approver,
                annual_leaves: payroll.annual_leaves,
            }), {
                headers: authHeader()
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    updatePayroll(payroll: IPayroll) {
        return axios
            .post(Config.API_URL + "pay.php", qs.stringify({
                action: "update_payroll",
                user_id: payroll.user_id,
                email: payroll.email,
                salary: payroll.salary,
                base_salary: payroll.base_salary,
                bonus: payroll.bonus,
                salary_rate: payroll.salary_rate,
                amercement_rate: payroll.amercement_rate,
                employee_type: payroll.employee_type,
                paid_full: payroll.paid_full,
                active: payroll.active,
                apply_month: payroll.apply_month,
                apply_year: payroll.apply_year,
                approver: payroll.approver,
                annual_leaves: payroll.annual_leaves,
            }), {
                headers: authHeader()
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    resign(payroll: IPayroll) {
        return axios
            .post(Config.API_URL + "pay.php", qs.stringify({
                action: "resign",
                user_id: payroll.user_id,
                email: payroll.email,
                salary: payroll.salary,
                base_salary: payroll.base_salary,
                bonus: payroll.bonus,
                salary_rate: payroll.salary_rate,
                amercement_rate: payroll.amercement_rate,
                employee_type: payroll.employee_type,
                paid_full: payroll.paid_full,
                active: payroll.active,
                apply_month: payroll.apply_month,
                apply_year: payroll.apply_year,
                apply_date: payroll.apply_date,
                approver: payroll.approver,
                description: payroll.description,
            }), {
                headers: authHeader()
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getPayslip(email: string, month: number, year: number) {
        let fromDate = new Date(year, month - 1, 26);
        let toDate = new Date(year, month, 25);
        return axios
            .post(Config.API_URL + "pay.php", qs.stringify({
                action: "payslip",
                email: email,
                from_day: 26,
                from_month: ("0" + DateTimeUtil.getMonth(fromDate.getMonth(), fromDate.getFullYear())).slice(-2),
                from_year: DateTimeUtil.getYear(fromDate.getMonth(), fromDate.getFullYear()),
                to_day: 25,
                to_month: ("0" + DateTimeUtil.getMonth(toDate.getMonth(), toDate.getFullYear())).slice(-2),
                to_year: DateTimeUtil.getYear(toDate.getMonth(), toDate.getFullYear())
            }), {
                headers: authHeader()
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getAllPayslips(month: number, year: number) {
        let fromDate = new Date(year, month - 1, 26);
        let toDate = new Date(year, month, 25);
        return axios
            .post(Config.API_URL + "pay.php", qs.stringify({
                action: "all_payslip",
                from_day: 26,
                from_month: ("0" + DateTimeUtil.getMonth(fromDate.getMonth(), fromDate.getFullYear())).slice(-2),
                from_year: DateTimeUtil.getYear(fromDate.getMonth(), fromDate.getFullYear()),
                to_day: 25,
                to_month: ("0" + DateTimeUtil.getMonth(toDate.getMonth(), toDate.getFullYear())).slice(-2),
                to_year: DateTimeUtil.getYear(toDate.getMonth(), toDate.getFullYear())
            }), {
                headers: authHeader()
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getAmercements(month: number, year: number) {
        let fromDate = new Date(year, month - 1, 26);
        let toDate = new Date(year, month, 25);
        return axios
            .post(Config.API_URL + "pay.php", qs.stringify({
                action: "all_amercement",
                from_day: 26,
                from_month: ("0" + DateTimeUtil.getMonth(fromDate.getMonth(), fromDate.getFullYear())).slice(-2),
                from_year: DateTimeUtil.getYear(fromDate.getMonth(), fromDate.getFullYear()),
                to_day: 25,
                to_month: ("0" + DateTimeUtil.getMonth(toDate.getMonth(), toDate.getFullYear())).slice(-2),
                to_year: DateTimeUtil.getYear(toDate.getMonth(), toDate.getFullYear())
            }), {
                headers: authHeader()
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getPayrollHistory(email: string) {
        return axios
            .post(Config.API_URL + "pay.php", qs.stringify({
                action: "payroll_history",
                email: email
            }), {
                headers: authHeader()
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getAllPayrollHistory() {
        return axios
            .post(Config.API_URL + "pay.php", qs.stringify({
                action: "all_payroll_history"
            }), {
                headers: authHeader()
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    approvePayslip(email: string, month: number, year: number) {
        let fromDate = new Date(year, month - 1, 26);
        let toDate = new Date(year, month, 25);
        return axios
            .post(Config.API_URL + "pay.php", qs.stringify({
                action: "approve_payslip",
                approver: email,
                from_day: 26,
                from_month: ("0" + DateTimeUtil.getMonth(fromDate.getMonth(), fromDate.getFullYear())).slice(-2),
                from_year: DateTimeUtil.getYear(fromDate.getMonth(), fromDate.getFullYear()),
                to_day: 25,
                to_month: ("0" + DateTimeUtil.getMonth(toDate.getMonth(), toDate.getFullYear())).slice(-2),
                to_year: DateTimeUtil.getYear(toDate.getMonth(), toDate.getFullYear())
            }), {
                headers: authHeader()
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    createDeduction(deduction: IDeduction) {
        return axios
            .post(Config.API_URL + "pay.php", qs.stringify({
                action: "create_deduction",
                user_id: deduction.user_id,
                email: deduction.email,
                month: deduction.month,
                year: deduction.year,
                money: deduction.money,
                reason: deduction.reason,
                is_bonus: deduction.is_bonus,
                approve: deduction.approve,
                approver: deduction.approver,
            }), {
                headers: authHeader()
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    createInstallment(deduction: IDeduction) {
        return axios
            .post(Config.API_URL + "pay.php", qs.stringify({
                action: "create_installment",
                user_id: deduction.user_id,
                email: deduction.email,
                month: deduction.month,
                year: deduction.year,
                money: deduction.money,
                reason: deduction.reason,
                installment_rate: deduction.installment_rate,
                total_months: deduction.total_months,
                month_end: deduction.month_end,
                year_end: deduction.year_end,
                approve: deduction.approve,
                approver: deduction.approver,
            }), {
                headers: authHeader()
            })
            .then(response => {
                // console.log(response.data);
                return response.data;
            });
    }

    getInstallments(email:string, month: number, year: number) {
        return axios
            .post(Config.API_URL + "pay.php", qs.stringify({
                action: "installment",
                email: email,
                month: month,
                year: year,
            }), {
                headers: authHeader()
            })
            .then(response => {
                return response.data;
            });
    }
}

export default new PayService();