import React from "react";
import IUser from "../types/user.type";
import AuthService from "../services/auth.service";
import AttendanceService from "../services/attendance.service";
import UserService from "../services/user.service";
import IAttendance from "../types/attendance.type";
import moment from "moment";
import ILeave from "../types/leave.type";
import LeaveService from "../services/leave.service";
import WorkingTime from "../types/workingtime.type";

type Props = {
    selectMonth: Date,
    email: string,
    time: string
}

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    selectDate: Date,
    email: string,
    time: string,
    curAttendances: IAttendance[],
    avgClockIn: number,
    avgClockInStr: string,
    avgClockOut: number,
    avgClockOutStr: string,
    avgWorkingHr: number,
    avgWorkingHrStr: string,
    absent: number,
    leaves: number,
    allowIps: string[],
    allUsers: IUser[],
    absentUsers: IUser[],
    leaveRequests: ILeave[],
    reqLeaveRequestsDone: boolean,
    reqUserDone: boolean,
    reqAttendancesDone: boolean,
}

export default class UserAttendance extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser,
            selectDate: this.props.selectMonth,
            email: this.props.email,
            time: this.props.time,
            curAttendances: [] as IAttendance[],
            avgClockIn: 0,
            avgClockInStr: "",
            avgClockOut: 0,
            avgClockOutStr: "",
            avgWorkingHr: 0,
            avgWorkingHrStr: "",
            absent: 0,
            leaves: 0,
            allowIps: [] as string[],
            allUsers: [] as IUser[],
            absentUsers: [] as IUser[],
            leaveRequests: [] as ILeave[],
            reqLeaveRequestsDone: false,
            reqUserDone: false,
            reqAttendancesDone: false,
        };
    }

    sumClockIn = 0;
    sumClockOut = 0;
    sumWorkingHr = 0;
    countWorkingHr = 0;

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                isModerator: user.roles?.includes("ROLE_MODERATOR"),
                isAdmin: user.roles?.includes("ROLE_ADMIN"),
            });
        }

        AuthService.getIp().then(
            (result) => {
                this.setState({
                    allowIps: result['allow_ips'] as string[]
                })
            }
        );
        WorkingTime.init(b => {
        });
    };

    componentDidUpdate = () => {
        if (this.state.selectDate != this.props.selectMonth) {
            this.setState({
                selectDate: this.props.selectMonth,
                curAttendances: [] as IAttendance[],
                avgClockIn: 0,
                avgClockOut: 0,
                avgWorkingHr: 0,
                absent: 0,
                leaves: 0,
                absentUsers: [] as IUser[],
                leaveRequests: [] as ILeave[],
                reqLeaveRequestsDone: false,
                reqAttendancesDone: false,
            });
            const now = new Date();
            const firstDay = new Date(2022, 1, 17);
            if (firstDay <= this.props.selectMonth && this.props.selectMonth <= now) {
                this.updateData();
            }
        }
    };

    updateData() {
        if (this.props.email == "all") {
            if (this.props.time == "today") {
                AttendanceService.getAllAttendancesByDay(this.props.selectMonth.getDate(), this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
                    (a: IAttendance[]) => {
                        this.setState({
                            reqAttendancesDone: true,
                            curAttendances: a
                        });
                        if (this.state.reqLeaveRequestsDone && this.state.reqUserDone) {
                            this.CalculateTime(a);
                        }
                    }
                );
                LeaveService.getAllLeaveRequestsByDay(this.props.selectMonth.getDate(), this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
                    (a: ILeave[]) => {
                        this.setState({
                            reqLeaveRequestsDone: true,
                            leaveRequests: a,
                        });
                        if (this.state.reqAttendancesDone && this.state.reqUserDone) {
                            this.CalculateTime(this.state.curAttendances);
                        }
                    }
                );
            } else {
                /*AttendanceService.getAllAttendances(this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
                    (a: IAttendance[]) => {
                        this.setState({
                            curAttendances: a
                        });
                        this.CalculateTime(a);
                    }
                );*/
                /*LeaveService.getAllLeaveRequests(this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
                    (a: ILeave[]) => {
                        this.setState({
                            leaveRequests: a
                        });
                        this.CalculateTime(this.state.curAttendances);
                    }
                );*/
            }
        } else {
            const queryParams = new URLSearchParams(window.location.search);
            const email = queryParams.get("email");
            AttendanceService.getAttendances(email != null && email != "" ? email : this.state.currentUser?.email!, this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
                (a: IAttendance[]) => {
                    this.setState({
                        reqAttendancesDone: true,
                        curAttendances: a
                    });
                    this.CalculateTime(a);
                }
            );
            /*LeaveService.getLeaveRequests(email != null && email != "" ? email : this.state.currentUser?.email!, this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
                (a: ILeave[]) => {
                    this.setState({
                        leaveRequests: a
                    });
                    this.CalculateTime(this.state.curAttendances);
                }
            );*/
        }
        if (this.state.allUsers == null || this.state.allUsers.length <= 0) {
            UserService.getAllBasicUsers().then(
                (r: IUser[]) => {
                    this.setState({
                        reqUserDone: true,
                        allUsers: r,
                    });
                    if (this.state.reqLeaveRequestsDone && this.state.reqAttendancesDone) {
                        this.CalculateTime(this.state.curAttendances);
                    }
                });
        } else {
            this.state.allUsers.map(u => {
                u.leave_request = null;
            });
        }
    };

    CalculateTime(curAttendances: IAttendance[]) {
        this.setState({
            avgClockIn: 0,
            avgClockInStr: "",
            avgClockOut: 0,
            avgClockOutStr: "",
            avgWorkingHr: 0,
            avgWorkingHrStr: "",
            absent: 0,
            leaves: 0,
        });
        this.sumClockIn = 0;
        this.sumClockOut = 0;
        this.sumWorkingHr = 0;
        this.countWorkingHr = 0;
        let avgClockIn = 0;
        let avgClockOut = 0;
        let avgWorkingHr = 0;
        let leaves = 0;
        let absentUsers = this.state.allUsers;

        if (curAttendances != null && curAttendances.length > 0) {
            for (let i = 0; i < curAttendances.length; i++) {
                let att = curAttendances[i] as IAttendance;
                const timeIn = this.getTime(att.clockin!);
                this.sumClockIn += timeIn.hour * 3600 + timeIn.minute * 60 + timeIn.second;
                if (att.clockout != null && att.clockout.trim() != "" && att.clockout.trim().length != 0) {
                    const timeOut = this.getTime(att.clockout);
                    this.sumClockOut += timeOut.hour * 3600 + timeOut.minute * 60 + timeOut.second;
                }
                if (Number(att.workinghr!) > 0) {
                    this.sumWorkingHr += Number(att.workinghr!);
                    this.countWorkingHr++;
                }
                if (this.state.allUsers.find((u) => {
                    return (u.email == curAttendances[i].email);
                }) != null) {
                    absentUsers = absentUsers.filter(u => u.email !== curAttendances[i].email && (u.resign_date == null || timeIn.date <= new Date(u.resign_date)));
                }
                let leave_req = this.state.leaveRequests.find((leave) => {
                    const start = moment(leave.start);
                    const end = moment(leave.end);
                    const d = moment(this.props.selectMonth);
                    return (att.email == leave.email && start <= d && d <= end);
                });
                const requests = this.state.leaveRequests.filter((leave) => {
                    const start = moment(leave.start);
                    const end = moment(leave.end);
                    const d = moment(this.props.selectMonth);
                    return (att.email == leave.email && start <= d && d <= end);
                });
                att.leave_request = null;
                if (leave_req != null) {
                    att.leave_request = leave_req;
                    att.leave_requests = requests;
                }
            }
            if (this.countWorkingHr > 0) {
                avgWorkingHr = this.sumWorkingHr / this.countWorkingHr;
                avgClockOut = this.sumClockOut / this.countWorkingHr;
            }
            if (curAttendances.length > 0) {
                avgClockIn = this.sumClockIn / curAttendances.length;
            }
        }
        this.state.allUsers.map((user, index) => {
            let leave_req = this.state.leaveRequests.find((att) => {
                return (att.email == user.email);
            });
            const requests = this.state.leaveRequests.filter((att) => {
                return (att.email == user.email);
            });
            if (requests != null) {
                user.leave_request = leave_req;
                user.leave_requests = requests;
                requests.map((leave_req) => {
                    if (leave_req.type != "Forgot_Check_In_Out" && leave_req.type != "Come_Late_Leave_Early") {
                        if (leave_req.leave_time == "am" || leave_req.leave_time == "pm") {
                            leaves += 0.5;
                        } else {
                            leaves++;
                        }
                    }
                });
            }
        });
        const a = absentUsers.filter(u => u.leave_request == null);
        const avgClockInStr = new Date(avgClockIn * 1000).toISOString().substr(11, 5);
        const avgClockOutStr = new Date(avgClockOut * 1000).toISOString().substr(11, 5);
        const avgWorkingHrStr = new Date(avgWorkingHr * 1000).toISOString().substr(11, 5);
        this.setState({
            avgClockIn: avgClockIn,
            avgClockInStr: avgClockInStr,
            avgClockOut: avgClockOut,
            avgClockOutStr: avgClockOutStr,
            avgWorkingHr: avgWorkingHr,
            avgWorkingHrStr: avgWorkingHrStr,
            absent: a.length,
            leaves: leaves,
            absentUsers: absentUsers
        });
    }

    getTime(time: string) {
        const dt = new Date(time.replace(/-/g, "/"));
        return {
            hour: dt.getHours(),
            minute: dt.getMinutes(),
            second: dt.getSeconds(),
            time: moment(dt).format("HH:mm"),
            date: dt
        };
    };

    CreateLink = (isAdmin: boolean, email: string, picture: string) => {
        if (isAdmin) {
            return (<a href={"/user?email=" + email}><img src={picture} alt="user" className="img-circle"
                                                          width="40"/></a>);
        } else {
            return (<img src={picture} alt="user" className="img-circle" width="40"/>);
        }
    };

    ShowLeaveType = (type: string) => {
        switch (type) {
            case 'Casual':
                return (<span className="text-default">{type!.replaceAll('_', ' ')}</span>);
            case 'Sick':
                return (<span className="text-danger">{type!.replaceAll('_', ' ')}</span>);
            case 'Annual':
                return (<span className="text-blue">{type!.replaceAll('_', ' ')}</span>);
            case 'Forgot_Check_In_Out':
                return (<span className="text-warning">{type!.replaceAll('_', ' ')}</span>);
            case 'Come_Late_Leave_Early':
                return (<span className="text-success">{type!.replaceAll('_', ' ')}</span>);
            case 'Work_From_Home':
                return (<span className="text-purple">{type!.replaceAll('_', ' ')}</span>);
        }
    };

    ShowStatus = (leave: ILeave) => {
        if (leave.approve == 0) {
            return (<span className="label label-danger font-weight-100">Declined</span>);
        } else if (leave.approve == 1) {
            return (<span className="label label-success font-weight-100">Approved</span>);
        } else {
            return (<span className="label label-warning font-weight-100">Awaiting</span>);
        }
    };

    ShowActions = (leave: ILeave) => {
        if (leave.approve == -1) {
            return (<>
                <button className="btn btn-sm btn-rounded btn-success"
                        onClick={() => this.ApproveRequest(leave, true)}>Approve
                </button>
                <button className="btn btn-sm btn-rounded btn-danger m-l-10"
                        onClick={() => this.ApproveRequest(leave, false)}>Decline
                </button>
            </>);
        } else {
            return (<span>by {leave.approver}</span>);
        }
    };

    ApproveRequest = (leave: ILeave, approve: boolean) => {
        LeaveService.approve(leave.id!, this.state.currentUser.email, approve ? 1 : 0).then(
            (result) => {
                window.location.reload();
                console.log("user.attendance ApproveRequest");
            }
        );
    };

    ShowLeaveTime = (leave: ILeave) => {
        if (leave.type != "Work_From_Home") {
            return (<span className="text-danger">{leave.reason}</span>);
        }
        switch (leave.leave_time) {
            case 'am':
                return (<span className="text-default">Morning Application</span>);
            case 'pm':
                return (<span className="text-purple">Afternoon Application</span>);
            case 'fd':
                return (<span
                    className="text-danger">{leave.reason}</span>/*<span className="text-success">Full Day Application</span>*/);
            case 'md':
                return (<span className="text-danger">{leave.reason}</span>/*<span
                    className="text-warning">{moment(leave.end).diff(leave.start, 'days') + 1} Days Application</span>*/);
        }
    };

    ShowLeaveTime2 = (leave: ILeave) => {
        switch (leave.leave_time) {
            case 'am':
                return (<span className="text-default">Morning</span>);
            case 'pm':
                return (<span className="text-purple">Afternoon</span>);
            case 'fd':
                return (<span className="text-success">Full Day</span>);
            case 'md':
                return (<span className="text-danger">{moment(leave.end).format("ddd, MMM DD")}</span>);
        }
    };

    ShowHeader() {
        if (this.state.email == "all") {
            return (<tr>
                {this.props.time !== "today" &&
                <th data-toggle="true"
                    className="footable-visible footable-first-column footable-sortable"> Date
                </th>}
                <th className="footable-visible footable-sortable"></th>
                <th className="footable-visible footable-sortable"> Name</th>
                <th className="footable-visible footable-sortable"> Clock In</th>
                <th className="footable-visible footable-sortable"> Clock Out</th>
                <th data-hide="phone" className="footable-visible footable-sortable"> Working Hr's</th>
                <th data-hide="phone" className="footable-visible footable-sortable">IP Clock In</th>
                <th data-hide="phone" className="footable-visible footable-last-column footable-sortable">IP Clock Out
                </th>
            </tr>)
        } else {
            return (<tr>
                <th data-toggle="true"
                    className="footable-visible footable-first-column footable-sortable"> Date
                </th>
                <th className="footable-visible footable-sortable"> Clock In</th>
                <th className="footable-visible footable-sortable"> Clock Out</th>
                <th data-hide="phone"
                    className="footable-visible footable-last-column footable-sortable"> Working
                    Hr's
                </th>
            </tr>)
        }
    }

    ShowTable() {
        const {curAttendances, email, isAdmin} = this.state;
        if (curAttendances != null) {
            if (email == "all") {
                if (this.props.time == "today") {
                    return curAttendances.map((att, i) => (
                        att.leave_requests == null ?
                            <tr key={att.id}>
                                <td>{this.CreateLink(isAdmin, att.email!, att.picture!)}</td>
                                <td>{att.name}</td>
                                <td><i className="mdi mdi-arrow-bottom-right text-purple"></i>
                                    {this.showClockIn(att.clockin, att.leave_requests)}</td>
                                <td><i className="mdi mdi-arrow-top-right text-info"></i>
                                    {this.showClockOut(att.clockout, att.workinghr!, att.leave_requests)}</td>
                                <td><span
                                    className="text-bold">{new Date(att.workinghr! * 1000).toISOString().substr(11, 5)} hr</span>
                                </td>
                                <td>{this.showIpClockIn(att.ip_clockin!)}</td>
                                <td>{att.ip_clockout}</td>
                            </tr>
                            :
                            <>
                                <tr key={att.id}>
                                    <td rowSpan={1 + att.leave_requests?.length!}>{this.CreateLink(isAdmin, att.email!, att.picture!)}</td>
                                    <td rowSpan={1 + att.leave_requests?.length!}>{att.name}</td>
                                    <td><i className="mdi mdi-arrow-bottom-right text-purple"></i>
                                        {this.showClockIn(att.clockin, att.leave_requests)}</td>
                                    <td><i className="mdi mdi-arrow-top-right text-info"></i>
                                        {this.showClockOut(att.clockout, att.workinghr!, att.leave_requests)}</td>
                                    <td><span
                                        className="text-bold">{new Date(att.workinghr! * 1000).toISOString().substr(11, 5)} hr</span>
                                    </td>
                                    <td>{this.showIpClockIn(att.ip_clockin!)}</td>
                                    <td>{att.ip_clockout}</td>
                                </tr>
                                {this.ShowLeaves(att.leave_requests!)}
                            </>
                    ))
                } else {
                    return curAttendances.map((att, i) => (
                        <tr key={att.id}>
                            <td> {moment(att.clockin).format("ddd, MMM DD")}</td>
                            <td>{this.CreateLink(isAdmin, att.email!, att.picture!)}</td>
                            <td>{att.name}</td>
                            <td><i className="mdi mdi-arrow-bottom-right text-purple"></i>
                                {this.showClockIn(att.clockin, att.leave_requests)}</td>
                            <td><i className="mdi mdi-arrow-top-right text-info"></i>
                                {this.showClockOut(att.clockout, att.workinghr!, att.leave_requests)}</td>
                            <td><span
                                className="text-bold">{new Date(att.workinghr! * 1000).toISOString().substr(11, 5)} hr</span>
                            </td>
                            <td>{this.showIpClockIn(att.ip_clockin!)}</td>
                            <td>{att.ip_clockout}</td>
                        </tr>
                    ))
                }
            } else {
                return curAttendances.map((att, i) => (
                    <tr key={att.id}>
                        <td> {moment(att.clockin).format("ddd, MMM DD")}</td>
                        <td><i className="mdi mdi-arrow-bottom-right text-purple"></i>
                            {this.showClockIn(att.clockin, att.leave_requests)}</td>
                        <td><i className="mdi mdi-arrow-top-right text-info"></i>
                            {this.showClockOut(att.clockout, att.workinghr!, att.leave_requests)}</td>
                        <td><span
                            className="text-bold">{new Date(att.workinghr! * 1000).toISOString().substr(11, 5)} hr</span>
                        </td>
                    </tr>
                ))
            }
        }
    };

    ShowLeaves(leaves: ILeave[]) {
        return leaves?.map(leave_request => (
            <tr>
                <td>{this.ShowLeaveType(leave_request?.type!)}</td>
                <td>{this.ShowLeaveTime2(leave_request)}</td>
                <td colSpan={2}>{leave_request?.reason}</td>
                <td>{this.ShowStatus(leave_request)}</td>
            </tr>
        ));
    }

    ShowLeaves2(isAdmin: boolean, leaves: ILeave[], user: IUser) {
        return leaves?.map(att => (
            <tr>
                <td>{this.CreateLink(isAdmin, att.email!, user.picture!)}</td>
                <td>{att.name}</td>
                <td>{this.ShowLeaveType(att.type!)}</td>
                <td>{this.ShowLeaveTime2(att)}</td>
                <td colSpan={2}>{this.ShowLeaveTime(att)}</td>
                <td>{this.ShowStatus(att)}</td>
            </tr>
        ));
    }

    showAbsent = () => {
        const {absentUsers, email, isAdmin} = this.state;
        if (absentUsers != null) {
            if (email == "all") {
                if (this.props.time == "today") {
                    return absentUsers.map((att, i) => (
                        (att.leave_requests == null || att.leave_requests.length <= 0) ?
                            <tr style={{backgroundColor: "rgba(251,58,58,0.13)"}}>
                                <td>{this.CreateLink(isAdmin, att.email!, att.picture!)}</td>
                                <td>{att.name}</td>
                                <td colSpan={5}><span className="text-bold-red">Absent</span></td>
                            </tr>
                            :
                            <>{this.ShowLeaves2(isAdmin, att.leave_requests, att)}</>
                    ))
                }
            }
        }
    };

    showClockIn(time: string | undefined, leaves: ILeave[] | undefined | null) {
        let clockIn = this.getTime(time!);
        let wtConfig = WorkingTime.getConfig(clockIn.date);
        console.log("wtConfig", wtConfig);
        const w = clockIn.date.getDay();
        const clockInInMinute = clockIn.hour * 60 + clockIn.minute;
        let checkInTime = wtConfig.getMorningTime(w)[0] * 60 + wtConfig.getMorningTime(w)[1];
        if (leaves != null && leaves != undefined && leaves.length > 0) {
            leaves.map(leave => {
                if (leave.type == "Casual" || leave.type == "Sick" || leave.type == "Annual" || leave.type == "Work_From_Home") {
                    if (leave.leave_time == "am") {
                        checkInTime = wtConfig.getAfternoonTime(w)[0] * 60 + wtConfig.getAfternoonTime(w)[1];
                    }
                }
                if ((leave.type == "Come_Late_Leave_Early" || leave.type == "Forgot_Check_In_Out") && leave.approve == 1) {
                    if (leave.leave_time == "am") {
                        checkInTime = clockInInMinute + 1;
                    }
                }
            });
        }
        if (clockInInMinute > checkInTime) {
            return (<span className="text-bold-red"> {clockIn.time}</span>);
        } else {
            return (<span className="text-bold-green"> {clockIn.time}</span>);
        }
    };

    showClockOut(time: string | undefined, workinghr: number, leaves: ILeave[] | undefined | null) {
        if (time != null && time.trim() != "" && time.trim().length > 0) {
            let clockOut = this.getTime(time);
            let wtConfig = WorkingTime.getConfig(clockOut.date);
            let wht = wtConfig.getWorkingHour(clockOut.date.getDay());
            let requireWorkingHr_full = wht * 60; //clockOut.date.getDay() == 6 ? 6 * 60 * 60 : 8 * 60 * 60;
            let requireWorkingHr_half = wht / 2 * 60; //clockOut.date.getDay() == 6 ? 3 * 60 * 60 : 4 * 60 * 60;
            let workingHr_full = requireWorkingHr_full;
            if (leaves != null && leaves != undefined && leaves.length > 0) {
                leaves.map(leave => {
                    if (leave.type == "Casual" || leave.type == "Sick" || leave.type == "Annual" || leave.type == "Work_From_Home") {
                        if (leave.leave_time == "am") {
                            workingHr_full = requireWorkingHr_half;
                        }
                        if (leave.leave_time == "pm") {
                            workingHr_full = requireWorkingHr_half - 60;
                        }
                    }
                    if (leave.type == "Come_Late_Leave_Early" && leave.leave_time == "pm" && workingHr_full > 5 * 60 * 60) {
                        workingHr_full = requireWorkingHr_full - 60;
                    }
                });
            }
            if (workinghr < workingHr_full) {
                return (<span className="text-bold-red">{clockOut.time}</span>);
            } else {
                return (<span className="text-bold-green">{clockOut.time}</span>);
            }
        } else {
            return (<span></span>);
        }
    }

    showIpClockIn(ip: string) {
        if (this.state.allowIps.indexOf(ip) < 0) {
            return (<span className="text-bold-red"> {ip}</span>);
        } else {
            return (<span className="text-bold-green"> {ip}</span>);
        }
    };

    render() {
        return (
            <>
                <div className="card-group">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/clockin.png" alt="assets/images/clockin.png"
                                         width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.state.avgClockInStr}</h3>
                                    <h6 className="card-subtitle">Avg Clock In</h6></div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/clockout.png"
                                         alt="assets/images/clockout.png" width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.state.avgClockOutStr}</h3>
                                    <h6 className="card-subtitle">Avg Clock Out</h6></div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/workinghr.png"
                                         alt="assets/images/workinghr.png" width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.state.avgWorkingHrStr}</h3>
                                    <h6 className="card-subtitle">Avg Working Hr</h6></div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="m-r-20 align-self-center">
                                    <img src="assets/images/absent.png" alt="assets/images/absent.png"
                                         width="40"/></div>
                                <div>
                                    <h3 className="card-title">{this.state.absent} / {this.state.leaves}</h3>
                                    <h6 className="card-subtitle">Absent / Leaves</h6></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table m-b-0 toggle-arrow-tiny color-table muted-table">
                        <thead>
                        {this.ShowHeader()}
                        </thead>
                        <tbody>
                        {this.showAbsent()}
                        {this.ShowTable()}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}