import React from "react";
import IUser from "../types/user.type";
import AuthService from "../services/auth.service";
import LeaveService from "../services/leave.service";
import ILeave from "../types/leave.type";
import moment from "moment";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IPayroll from "../types/payroll.type";
import PayService from "../services/pay.service";

type Props = {
    selectMonth: Date,
    email: string,
}

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    selectMonth: Date,
    email: string,
    leaveRequests: ILeave[],
    newLeaveRequest: ILeave,
    curPayroll: IPayroll,
}

export default class UserLeave extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser,
            selectMonth: this.props.selectMonth,
            email: this.props.email,
            leaveRequests: [] as ILeave[],
            curPayroll: {} as IPayroll,
            newLeaveRequest: {
                type: 'Casual',
                start: moment().format("YYYY-MM-DD"),
                end: moment().format("YYYY-MM-DD"),
                leave_time: "fd",
                req_annual_days: 0
            } as ILeave
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.handleChangeReason = this.handleChangeReason.bind(this);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
        this.handleChangeLeaveTime = this.handleChangeLeaveTime.bind(this);
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                isModerator: user.roles?.includes("ROLE_MODERATOR"),
                isAdmin: user.roles?.includes("ROLE_ADMIN"),
            });
        }
    };

    componentDidUpdate = () => {
        if (this.state.selectMonth != this.props.selectMonth) {
            this.setState({
                selectMonth: this.props.selectMonth
            });
            this.updateData();
        }
    };

    updateData() {
        const queryParams = new URLSearchParams(window.location.search);
        const email = queryParams.get("email");
        if (this.props.email == "all") {
            LeaveService.getAllLeaveRequests(this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
                (a: ILeave[]) => {
                    a.map(leave => {
                        if (leave.type == 'Annual') {
                            leave.req_annual_days = this.GetLeaveCount(leave);
                        }
                    });
                    this.setState({
                        leaveRequests: a
                    });
                }
            );
        } else {
            LeaveService.getLeaveRequests(email != null && email != "" ? email : this.state.currentUser?.email!, this.props.selectMonth.getMonth() + 1, this.props.selectMonth.getFullYear()).then(
                (a: ILeave[]) => {
                    a.map(leave => {
                        if (leave.type == 'Annual') {
                            leave.req_annual_days = this.GetLeaveCount(leave);
                        }
                    });
                    this.setState({
                        leaveRequests: a
                    });
                }
            );
            PayService.getPayroll(this.state.currentUser?.email!).then(
                (a: IPayroll) => {
                    this.setState({
                        curPayroll: a
                    });
                }
            );
        }
    };

    ShowHeader() {
        if (this.state.email == "all") {
            return (<tr>
                <th data-toggle="true" className="footable-visible footable-first-column footable-sortable"> Name</th>
                <th className="footable-visible footable-sortable"> Date</th>
                <th className="footable-visible footable-sortable">To</th>
                <th data-hide="phone" className="footable-visible footable-sortable"> Type</th>
                <th data-hide="phone" className="footable-visible footable-sortable"> Reason</th>
                <th data-hide="phone" className="footable-visible footable-sortable"> Status</th>
                <th data-hide="phone" className="footable-visible footable-last-column footable-sortable">Actions</th>
            </tr>)
        } else {
            return (<tr>
                <th data-toggle="true" className="footable-visible footable-first-column footable-sortable"> Date</th>
                <th className="footable-visible footable-sortable"> End</th>
                <th data-hide="phone" className="footable-visible footable-sortable"> Type</th>
                <th data-hide="phone" className="footable-visible footable-sortable"> Reason</th>
                <th data-hide="phone" className="footable-visible footable-sortable"> Status</th>
                <th data-hide="phone" className="footable-visible footable-last-column footable-sortable">Actions</th>
            </tr>)
        }
    }

    ShowLeaveType = (type: string) => {
        switch (type) {
            case 'Casual':
                return (<span className="text-default">{type!.replaceAll('_', ' ')}</span>);
            case 'Sick':
                return (<span className="text-danger">{type!.replaceAll('_', ' ')}</span>);
            case 'Annual':
                return (<span className="text-blue">{type!.replaceAll('_', ' ')}</span>);
            case 'Forgot_Check_In_Out':
                return (<span className="text-warning">{type!.replaceAll('_', ' ')}</span>);
            case 'Come_Late_Leave_Early':
                return (<span className="text-success">{type!.replaceAll('_', ' ')}</span>);
            case 'Work_From_Home':
                return (<span className="text-purple">{type!.replaceAll('_', ' ')}</span>);
        }
    };

    ShowLeaveTime = (leave: ILeave) => {
        switch (leave.leave_time) {
            case 'am':
                return (<span className="text-default">Morning</span>);
            case 'pm':
                return (<span className="text-purple">Afternoon</span>);
            case 'fd':
                return (<span className="text-success">Full Day</span>);
            case 'md':
                return (<span className="text-danger">{moment(leave.end).format("ddd, MMM DD")}</span>);
        }
    };

    ShowStatus = (leave: ILeave) => {
        if (leave.approve == 0) {
            return (<span className="label label-danger font-weight-100">Declined</span>);
        } else if (leave.approve == 1) {
            return (
                <>
                    <span className="label label-success font-weight-100">Approved
                        < br/>
                        <small> by {leave.approver}</small>
                    </span>
                </>);
        } else {
            return (<span className="label label-warning font-weight-100">Awaiting</span>);
        }
    };

    ShowActions = (leave: ILeave) => {
        // if (leave.approve == -1) {
        return (<>
            {leave.approve != 1 && <button className="btn btn-sm btn-rounded btn-success"
                                           onClick={() => this.ApproveRequest(leave, true)}>Approve
            </button>}
            {leave.approve != 0 && <button className="btn btn-sm btn-rounded btn-outline-danger m-l-10"
                                           onClick={() => this.ApproveRequest(leave, false)}>Decline
            </button>}
            <button data-toggle="modal" data-target="#request-leave-modal"
                    className="btn btn-sm btn-rounded btn-outline-info m-l-10"
                    onClick={() => this.UpdateRequest(leave)}>Update
            </button>
            <button data-toggle="modal" data-target="#delete-request-leave-modal"
                    className="btn btn-sm btn-rounded btn-danger m-l-10"
                    onClick={() => this.ConfirmDeleteRequest(leave)}>Delete
            </button>
        </>);
        /*} else {
            return (<>
                <button className="btn btn-sm btn-circle btn-success" data-toggle="tooltip" data-placement="top"
                        title="Approve"
                        onClick={() => this.ApproveRequest(leave, true)}><i className="fa fa-check"></i>
                </button>
                <button className="btn btn-sm btn-circle btn-outline-danger m-l-10" data-toggle="tooltip"
                        data-placement="top" title="Decline"
                        onClick={() => this.ApproveRequest(leave, false)}><i className="fa fa-minus"></i>
                </button>
                <button data-toggle="modal" data-target="#request-leave-modal"
                        className="btn btn-sm btn-circle btn-outline-info m-l-10"
                        onClick={() => this.UpdateRequest(leave)}><i className="fa fa-pencil"></i>
                </button>
                <button data-toggle="modal" data-target="#delete-request-leave-modal"
                        className="btn btn-sm btn-circle btn-danger m-l-10"
                        onClick={() => this.ConfirmDeleteRequest(leave)}><i className="fa fa-remove"></i>
                </button>
                {/!*<span> by {leave.approver?.replace("@teras.vn", "")}</span>*!/}
            </>);
        }*/
    };

    ApproveRequest = (leave: ILeave, approve: boolean) => {
        LeaveService.approve(leave.id!, this.state.currentUser.email, approve ? 1 : 0).then(
            (result) => {
                window.location.reload();
            }
        );
    };

    UpdateRequest = (leave: ILeave) => {
        PayService.getPayroll(leave?.email!).then(
            (a: IPayroll) => {
                this.setState({
                    curPayroll: a
                });
            }
        );
        this.setState({
            newLeaveRequest: leave
        });
    };

    ConfirmDeleteRequest = (leave: ILeave) => {
        this.setState({
            newLeaveRequest: leave
        });
    };

    DeleteRequest = () => {
        LeaveService.delete(this.state.newLeaveRequest.id!).then(
            (result) => {
                window.location.reload();
            }
        );
    };

    CreateLink = (isAdmin: boolean, email: string, name: string) => {
        if (isAdmin) {
            return (<a href={"/user?email=" + email}><span>{name}</span></a>);
        } else {
            return (<span>{name}</span>);
        }
    };

    ShowTable() {
        const {leaveRequests, email, isAdmin} = this.state;
        if (leaveRequests != null) {
            if (email == "all") {
                return leaveRequests.map((att, i) => (
                    <tr key={att.id}>
                        <td>{this.CreateLink(isAdmin, att.email!, att.name!)}</td>
                        <td>{moment(att.start).format("ddd, MMM DD")}</td>
                        <td>{this.ShowLeaveTime(att)}</td>
                        <td>{this.ShowLeaveType(att.type!)}</td>
                        <td>{att.reason}</td>
                        <td>{this.ShowStatus(att)}</td>
                        <td>{this.ShowActions(att)}</td>
                    </tr>
                ))
            } else {
                return leaveRequests.map((att, i) => (
                    <tr key={att.id}>
                        <td> {moment(att.start).format("ddd, MMM DD")}</td>
                        <td>{this.ShowLeaveTime(att)}</td>
                        <td>{this.ShowLeaveType(att.type!)}</td>
                        <td>{att.reason}</td>
                        <td>{this.ShowStatus(att)}</td>
                        <td>{this.ShowActions(att)}</td>
                    </tr>
                ))
            }
        }
    };

    ShowLeaveTag = (approve: number) => {
        if (approve == 0) {
            return (<span className="pull-right label label-danger label-rounded">Declined</span>);
        } else if (approve == 1) {
            return (<span className="pull-right label label-success label-rounded">Approved</span>);
        } else {
            return (<span className="pull-right label label-warning label-rounded">Awaiting</span>);
        }
    };

    ShowLeaveTime2 = (leave: ILeave) => {
        switch (leave.leave_time) {
            case 'am':
                return (<>Morning Application</>);
            case 'pm':
                return (<>Afternoon Application</>);
            case 'fd':
                return (<>Full Day Application</>);
            case 'md':
                return (<>{moment(leave.end).diff(leave.start, 'days') + 1} Days Application</>);
        }
    };

    ShowLeaveRequests = () => {
        const {leaveRequests} = this.state;
        if (leaveRequests != null) {
            return leaveRequests.map((att, i) => (
                <div style={{cursor: "pointer"}} data-toggle="modal" data-target="#update-request-leave-modal"
                     onClick={() => this.UpdateRequest(att)}>
                    <div className="card-body p-0">
                        {this.ShowLeaveTag(att.approve!)}
                        <h5 className="card-subtitle">{this.ShowLeaveType(att.type!)}</h5>
                        <h4 className="card-title text-bold">{moment(att.start).format("ddd, MMM DD")} {att.leave_time == 'md' ? ("- " + moment(att.end).format("ddd, MMM DD")) : ""}</h4>
                        <small className="pull-right">Show Detail</small>
                        <h6 className="card-subtitle">{this.ShowLeaveTime2(att)}</h6>
                    </div>
                    <hr/>
                </div>
            ))
        }
    };

    ShowLeaveRequestDetail = (id: number) => {
        console.log("show detail: " + id);
    };

    ShowToast = (message: string) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    GetLeaveCount = (leave: ILeave) => {
        switch (leave.leave_time) {
            case 'am':
            case 'pm':
                return 0.5;
            case 'fd':
                return 1;
            case 'md':
                return moment(leave.end).diff(leave.start, 'days') + 1;
        }
    };

    handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        const {currentUser, newLeaveRequest, curPayroll} = this.state;
        if (newLeaveRequest.type == null || newLeaveRequest.type.trim() == '') {
            newLeaveRequest.type = 'Casual';
        }
        if (newLeaveRequest.start == null) {
            this.ShowToast("Start day can not be empty!");
            return;
        }
        if (newLeaveRequest.end == null) {
            this.ShowToast("End day can not be empty!");
            return;
        }
        if (newLeaveRequest.reason == null) {
            this.ShowToast("Reason can not be empty!");
            return;
        }
        if (newLeaveRequest.type == 'Annual' && newLeaveRequest.req_annual_days! > curPayroll.annual_leaves!) {
            this.ShowToast("Annual leave is not enough!");
            return;
        }
        LeaveService.request(currentUser.email!, newLeaveRequest.type!, newLeaveRequest.start!, newLeaveRequest.leave_time!, newLeaveRequest.end!, newLeaveRequest.reason!, newLeaveRequest.req_annual_days!).then(
            (result) => {
                if (result['code'] == 0) {
                    //close modal
                    window.location.reload();
                } else {
                }
            });
    };

    handleUpdate: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        const {currentUser, newLeaveRequest, curPayroll} = this.state;
        if (newLeaveRequest.type == null || newLeaveRequest.type.trim() == '') {
            newLeaveRequest.type = 'Casual';
        }
        if (newLeaveRequest.start == null) {
            this.ShowToast("Start day can not be empty!");
            return;
        }
        if (newLeaveRequest.end == null) {
            this.ShowToast("End day can not be empty!");
            return;
        }
        if (newLeaveRequest.reason == null) {
            this.ShowToast("Reason can not be empty!");
            return;
        }
        if (newLeaveRequest.type == 'Annual' && newLeaveRequest.req_annual_days! > curPayroll.annual_leaves!) {
            this.ShowToast("Annual leave is not enough!");
            return;
        }
        LeaveService.update(currentUser.email!, newLeaveRequest.id!, newLeaveRequest.type!, newLeaveRequest.start!, newLeaveRequest.leave_time!, newLeaveRequest.end!, newLeaveRequest.reason!, newLeaveRequest.req_annual_days!).then(
            (result) => {
                if (result['code'] == 0) {
                    //close modal
                    window.location.reload();
                } else {
                }
            });
    };

    handleChangeType(event: React.ChangeEvent<HTMLSelectElement>) {
        let newLeaveRequest = this.state.newLeaveRequest;
        newLeaveRequest.type = event.target.value;
        if (newLeaveRequest.type == 'Annual') {
            newLeaveRequest.req_annual_days = this.GetLeaveCount(newLeaveRequest);
        }
        if ((newLeaveRequest.type == 'Forgot_Check_In_Out' || newLeaveRequest.type == 'Come_Late_Leave_Early') && (newLeaveRequest.leave_time == "md" || newLeaveRequest.leave_time == "fd")) {
            newLeaveRequest.leave_time = "am";
        }
        this.setState({
            newLeaveRequest: newLeaveRequest
        });
    }

    handleChangeReason(event: React.ChangeEvent<HTMLInputElement>) {
        let newLeaveRequest = this.state.newLeaveRequest;
        newLeaveRequest.reason = event.target.value;
        this.setState({
            newLeaveRequest: newLeaveRequest
        });
    }

    handleChangeStartDate(event: React.ChangeEvent<HTMLInputElement>) {
        let newLeaveRequest = this.state.newLeaveRequest;
        newLeaveRequest.start = event.target.value;
        newLeaveRequest.end = event.target.value;
        if (newLeaveRequest.type == 'Annual') {
            newLeaveRequest.req_annual_days = this.GetLeaveCount(newLeaveRequest);
        }
        this.setState({
            newLeaveRequest: newLeaveRequest
        });
    }

    handleChangeLeaveTime(event: React.ChangeEvent<HTMLInputElement>) {
        let newLeaveRequest = this.state.newLeaveRequest;
        newLeaveRequest.leave_time = event.target.value;
        newLeaveRequest.end = newLeaveRequest.start;
        if (newLeaveRequest.type == 'Annual') {
            newLeaveRequest.req_annual_days = this.GetLeaveCount(newLeaveRequest);
        }
        this.setState({
            newLeaveRequest: newLeaveRequest
        });
    }

    handleChangeEndDate(event: React.ChangeEvent<HTMLInputElement>) {
        let newLeaveRequest = this.state.newLeaveRequest;
        newLeaveRequest.end = event.target.value;
        if (newLeaveRequest.type == 'Annual') {
            newLeaveRequest.req_annual_days = this.GetLeaveCount(newLeaveRequest);
        }
        this.setState({
            newLeaveRequest: newLeaveRequest
        });
    }

    render() {
        const {newLeaveRequest, email, curPayroll, currentUser} = this.state;
        if (email == "all") {
            return (
                <div>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <div className="table-responsive">
                        <table className="table m-b-0 toggle-arrow-tiny color-table muted-table">
                            <thead>
                            {this.ShowHeader()}
                            </thead>
                            <tbody>
                            {this.ShowTable()}
                            </tbody>
                        </table>
                    </div>
                    <div id="delete-request-leave-modal" className="modal fade" role="dialog"
                         aria-labelledby="deleteLeaveModal" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Delete Request</h4>
                                    <button type="button" className="close" data-dismiss="modal"
                                            aria-hidden="true">×
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <span>Do you want to delete this leave request?</span>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={() => this.DeleteRequest()}
                                            className="btn btn-danger waves-effect waves-light">Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="request-leave-modal" className="modal fade" role="dialog"
                         aria-labelledby="requestLeaveModal" aria-hidden="true">
                        <form className="form-horizontal form-material" onSubmit={this.handleUpdate}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Update Leave Request</h4>
                                        <button type="button" className="close" data-dismiss="modal"
                                                aria-hidden="true">×
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <label className="col-sm-12">Type</label>
                                            <div className="col-sm-12 input-group">
                                                <div className="input-group-addon"><i className="ti-view-list"></i>
                                                </div>
                                                <select className="form-control form-control-line" name="type"
                                                        id="type"
                                                        onChange={this.handleChangeType}>
                                                    <option value="Casual"
                                                            selected={newLeaveRequest.type == 'Casual'}>Casual Leave
                                                    </option>
                                                    <option value="Sick"
                                                            selected={newLeaveRequest.type == 'Sick'}>Sick Leave
                                                    </option>
                                                    <option value="Annual"
                                                            selected={newLeaveRequest.type == 'Annual'}>Annual Leave
                                                    </option>
                                                    <option value="Forgot_Check_In_Out"
                                                            selected={newLeaveRequest.type == 'Forgot_Check_In_Out'}>Forgot
                                                        Check In/Out
                                                    </option>
                                                    <option value="Come_Late_Leave_Early"
                                                            selected={newLeaveRequest.type == 'Come_Late_Leave_Early'}>Come
                                                        Late/Leave Early
                                                    </option>
                                                    <option value="Work_From_Home"
                                                            selected={newLeaveRequest.type == 'Work_From_Home'}>Work
                                                        From Home
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        {newLeaveRequest.type == 'Annual' && <div className="form-group">
                                            <label className="col-md-12">Requesting / Remaining</label>
                                            <div className="col-md-12 input-group">
                                                <label
                                                    className="text-warning">{newLeaveRequest.req_annual_days}</label> / {curPayroll?.annual_leaves} days
                                            </div>
                                        </div>}
                                        <div className="form-group">
                                            <label className="col-md-12">Date</label>
                                            <div className="col-md-12 input-group">
                                                <div className="input-group-addon"><i className="ti-time"></i></div>
                                                <input type="date" value={newLeaveRequest.start}
                                                       onChange={this.handleChangeStartDate}
                                                       className="form-control form-control-line" name="start"
                                                       id="start"/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/*<label className="col-md-12">End Date</label>*/}
                                            <div className="col-md-12 input-group">
                                                {/*<div className="input-group-addon"><i className="ti-time"></i></div>*/}
                                                {/*<input type="datetime-local" value={newLeaveRequest.end}
                                                       onChange={this.handleChangeEndDate}
                                                       className="form-control form-control-line" name="end"
                                                       id="end"/>*/}
                                                <input name="leave_time" type="radio" id="am" value="am"
                                                       checked={this.state.newLeaveRequest.leave_time === "am"}
                                                       onChange={this.handleChangeLeaveTime}
                                                       className="radio-col-deep-orange"/>
                                                <label htmlFor="am">Morning</label>
                                                <input name="leave_time" type="radio" id="pm" value="pm"
                                                       checked={this.state.newLeaveRequest.leave_time === "pm"}
                                                       onChange={this.handleChangeLeaveTime}
                                                       className="radio-col-deep-orange"/>
                                                <label htmlFor="pm">Afternoon</label>
                                                {newLeaveRequest.type != 'Forgot_Check_In_Out' && newLeaveRequest.type != 'Come_Late_Leave_Early' && <>
                                                    <input name="leave_time" type="radio" id="fd" value="fd"
                                                           checked={this.state.newLeaveRequest.leave_time === "fd"}
                                                           onChange={this.handleChangeLeaveTime}
                                                           className="radio-col-deep-orange"/>
                                                    <label htmlFor="fd">Full Day</label></>}
                                                {newLeaveRequest.type != 'Forgot_Check_In_Out' && newLeaveRequest.type != 'Come_Late_Leave_Early' && <>
                                                    <input name="leave_time" type="radio" id="md" value="md"
                                                           checked={this.state.newLeaveRequest.leave_time === "md"}
                                                           onChange={this.handleChangeLeaveTime}
                                                           className="radio-col-deep-orange"/>
                                                    <label htmlFor="md">Multi Days</label></>}
                                            </div>
                                        </div>
                                        {this.state.newLeaveRequest.leave_time === "md" &&
                                        <div className="form-group">
                                            <label className="col-md-12">End Date</label>
                                            <div className="col-md-12 input-group">
                                                <div className="input-group-addon"><i className="ti-time"></i></div>
                                                <input type="date" value={newLeaveRequest.end}
                                                       onChange={this.handleChangeEndDate}
                                                       className="form-control form-control-line" name="end"
                                                       id="end"/>
                                            </div>
                                        </div>}
                                        <div className="form-group">
                                            <label className="col-md-12">Reason</label>
                                            <div className="col-md-12 input-group">
                                                <div className="input-group-addon"><i className="ti-pencil-alt"></i>
                                                </div>
                                                <input type="text" value={newLeaveRequest.reason}
                                                       onChange={this.handleChangeReason}
                                                       className="form-control form-control-line" name="reason"
                                                       id="reason"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="submit"
                                                className="btn btn-info waves-effect waves-light">Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="card">
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <div className="card-header text-center">
                        <div className="d-flex text-center">
                            <div className="m-r-20 align-self-center">
                                <img src="assets/images/clockin.png" alt="assets/images/clockin.png"
                                     width="25"/>
                            </div>
                            <h4 className="text-center m-t-10">Leave Requests</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        {email == currentUser.email && <>
                            <div className="text-center">
                                <button data-toggle="modal" data-target="#request-leave-modal"
                                        className="m-t-10 m-b-10 waves-effect waves-dark btn btn-primary btn-md btn-rounded">Create
                                    Request
                                </button>
                            </div>
                            <div>
                                <hr/>
                            </div>
                        </>}
                        <div>
                            {this.ShowLeaveRequests()}
                        </div>
                        <div id="request-leave-modal" className="modal fade" role="dialog"
                             aria-labelledby="requestLeaveModal" aria-hidden="true">
                            <form className="form-horizontal form-material" onSubmit={this.handleSubmit}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">Create Leave Request</h4>
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-hidden="true">×
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <label className="col-sm-12">Type</label>
                                                <div className="col-sm-12 input-group">
                                                    <div className="input-group-addon"><i className="ti-view-list"></i>
                                                    </div>
                                                    <select className="form-control form-control-line" name="type"
                                                            id="type"
                                                            onChange={this.handleChangeType}>
                                                        <option value="Casual"
                                                                selected={newLeaveRequest.type == 'Casual'}>Casual Leave
                                                        </option>
                                                        <option value="Sick"
                                                                selected={newLeaveRequest.type == 'Sick'}>Sick Leave
                                                        </option>
                                                        {curPayroll.employee_type == "official" &&
                                                        <option value="Annual"
                                                                selected={newLeaveRequest.type == 'Annual'}>Annual
                                                            Leave</option>}
                                                        <option value="Forgot_Check_In_Out"
                                                                selected={newLeaveRequest.type == 'Forgot_Check_In_Out'}>Forgot
                                                            Check In/Out
                                                        </option>
                                                        <option value="Come_Late_Leave_Early"
                                                                selected={newLeaveRequest.type == 'Come_Late_Leave_Early'}>Come
                                                            Late/Leave Early
                                                        </option>
                                                        <option value="Work_From_Home"
                                                                selected={newLeaveRequest.type == 'Work_From_Home'}>Work
                                                            From Home
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            {newLeaveRequest.type == 'Annual' && <div className="form-group">
                                                <label className="col-md-12">Requesting / Remaining</label>
                                                <div className="col-md-12 input-group">
                                                    <label
                                                        className="text-warning">{newLeaveRequest.req_annual_days}</label> / {curPayroll?.annual_leaves} days
                                                </div>
                                            </div>}
                                            <div className="form-group">
                                                <label className="col-md-12">Date</label>
                                                <div className="col-md-12 input-group">
                                                    <div className="input-group-addon"><i className="ti-time"></i></div>
                                                    <input type="date" value={newLeaveRequest.start}
                                                           onChange={this.handleChangeStartDate}
                                                           className="form-control form-control-line" name="start"
                                                           id="start"/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                {/*<label className="col-md-12">End Date</label>*/}
                                                <div className="col-md-12 input-group">
                                                    {/*<div className="input-group-addon"><i className="ti-time"></i></div>*/}
                                                    {/*<input type="datetime-local" value={newLeaveRequest.end}
                                                       onChange={this.handleChangeEndDate}
                                                       className="form-control form-control-line" name="end"
                                                       id="end"/>*/}
                                                    <input name="leave_time" type="radio" id="am" value="am"
                                                           checked={this.state.newLeaveRequest.leave_time === "am"}
                                                           onChange={this.handleChangeLeaveTime}
                                                           className="radio-col-deep-orange"/>
                                                    <label htmlFor="am">Morning</label>
                                                    <input name="leave_time" type="radio" id="pm" value="pm"
                                                           checked={this.state.newLeaveRequest.leave_time === "pm"}
                                                           onChange={this.handleChangeLeaveTime}
                                                           className="radio-col-deep-orange"/>
                                                    <label htmlFor="pm">Afternoon</label>
                                                    {newLeaveRequest.type != 'Forgot_Check_In_Out' && newLeaveRequest.type != 'Come_Late_Leave_Early' && <>
                                                        <input name="leave_time" type="radio" id="fd" value="fd"
                                                               checked={this.state.newLeaveRequest.leave_time === "fd"}
                                                               onChange={this.handleChangeLeaveTime}
                                                               className="radio-col-deep-orange"/>
                                                        <label htmlFor="fd">Full Day</label></>}
                                                    {newLeaveRequest.type != 'Forgot_Check_In_Out' && newLeaveRequest.type != 'Come_Late_Leave_Early' && <>
                                                        <input name="leave_time" type="radio" id="md" value="md"
                                                               checked={this.state.newLeaveRequest.leave_time === "md"}
                                                               onChange={this.handleChangeLeaveTime}
                                                               className="radio-col-deep-orange"/>
                                                        <label htmlFor="md">Multi Days</label></>}
                                                </div>
                                            </div>
                                            {this.state.newLeaveRequest.leave_time === "md" &&
                                            <div className="form-group">
                                                <label className="col-md-12">End Date</label>
                                                <div className="col-md-12 input-group">
                                                    <div className="input-group-addon"><i className="ti-time"></i></div>
                                                    <input type="date" value={newLeaveRequest.end}
                                                           onChange={this.handleChangeEndDate}
                                                           className="form-control form-control-line" name="end"
                                                           id="end"/>
                                                </div>
                                            </div>}
                                            <div className="form-group">
                                                <label className="col-md-12">Reason</label>
                                                <div className="col-md-12 input-group">
                                                    <div className="input-group-addon"><i className="ti-pencil-alt"></i>
                                                    </div>
                                                    <input type="text" value={newLeaveRequest.reason}
                                                           onChange={this.handleChangeReason}
                                                           className="form-control form-control-line" name="reason"
                                                           id="reason"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="submit"
                                                    className="btn btn-danger waves-effect waves-light">Request
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div id="update-request-leave-modal" className="modal fade" role="dialog"
                             aria-labelledby="updateLeaveModal" aria-hidden="true">
                            <form className="form-horizontal form-material" onSubmit={this.handleUpdate}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">Update Leave Request</h4>
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-hidden="true">×
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-group">
                                                <label className="col-sm-12">Type</label>
                                                <div className="col-sm-12 input-group">
                                                    <div className="input-group-addon"><i className="ti-view-list"></i>
                                                    </div>
                                                    <select className="form-control form-control-line" name="type"
                                                            id="type" disabled={newLeaveRequest.approve != -1}
                                                            onChange={this.handleChangeType}>
                                                        <option value="Casual"
                                                                selected={newLeaveRequest.type == 'Casual'}>Casual Leave
                                                        </option>
                                                        <option value="Sick"
                                                                selected={newLeaveRequest.type == 'Sick'}>Sick Leave
                                                        </option>
                                                        {curPayroll?.employee_type == "official" &&
                                                        <option value="Annual"
                                                                selected={newLeaveRequest.type == 'Annual'}>Annual
                                                            Leave</option>}
                                                        <option value="Forgot_Check_In_Out"
                                                                selected={newLeaveRequest.type == 'Forgot_Check_In_Out'}>Forgot
                                                            Check In/Out
                                                        </option>
                                                        <option value="Come_Late_Leave_Early"
                                                                selected={newLeaveRequest.type == 'Come_Late_Leave_Early'}>Come
                                                            Late/Leave Early
                                                        </option>
                                                        <option value="Work_From_Home"
                                                                selected={newLeaveRequest.type == 'Work_From_Home'}>Work
                                                            From Home
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            {newLeaveRequest.type == 'Annual' && <div className="form-group">
                                                <label className="col-md-12">Requesting / Remaining</label>
                                                <div className="col-md-12 input-group">
                                                    <label
                                                        className="text-warning">{newLeaveRequest.req_annual_days}</label> / {curPayroll?.annual_leaves} days
                                                </div>
                                            </div>}
                                            <div className="form-group">
                                                <label className="col-md-12">Date</label>
                                                <div className="col-md-12 input-group">
                                                    <div className="input-group-addon"><i className="ti-time"></i></div>
                                                    <input type="date" value={newLeaveRequest.start}
                                                           onChange={this.handleChangeStartDate}
                                                           className="form-control form-control-line" name="start"
                                                           disabled={newLeaveRequest.approve != -1}
                                                           id="start"/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                {/*<label className="col-md-12">End Date</label>*/}
                                                <div className="col-md-12 input-group">
                                                    {/*<div className="input-group-addon"><i className="ti-time"></i></div>*/}
                                                    {/*<input type="datetime-local" value={newLeaveRequest.end}
                                                       onChange={this.handleChangeEndDate}
                                                       className="form-control form-control-line" name="end"
                                                       id="end"/>*/}
                                                    <input name="leave_time" type="radio" id="am" value="am"
                                                           checked={this.state.newLeaveRequest.leave_time === "am"}
                                                           onChange={this.handleChangeLeaveTime}
                                                           disabled={newLeaveRequest.approve != -1}
                                                           className="radio-col-deep-orange"/>
                                                    <label htmlFor="am">Morning</label>
                                                    <input name="leave_time" type="radio" id="pm" value="pm"
                                                           checked={this.state.newLeaveRequest.leave_time === "pm"}
                                                           onChange={this.handleChangeLeaveTime}
                                                           disabled={newLeaveRequest.approve != -1}
                                                           className="radio-col-deep-orange"/>
                                                    <label htmlFor="pm">Afternoon</label>
                                                    {newLeaveRequest.type != 'Forgot_Check_In_Out' && newLeaveRequest.type != 'Come_Late_Leave_Early' && <>
                                                        <input name="leave_time" type="radio" id="fd" value="fd"
                                                               checked={this.state.newLeaveRequest.leave_time === "fd"}
                                                               onChange={this.handleChangeLeaveTime}
                                                               disabled={newLeaveRequest.approve != -1}
                                                               className="radio-col-deep-orange"/>
                                                        <label htmlFor="fd">Full Day</label></>}
                                                    {newLeaveRequest.type != 'Forgot_Check_In_Out' && newLeaveRequest.type != 'Come_Late_Leave_Early' && <>
                                                        <input name="leave_time" type="radio" id="md" value="md"
                                                               checked={this.state.newLeaveRequest.leave_time === "md"}
                                                               onChange={this.handleChangeLeaveTime}
                                                               disabled={newLeaveRequest.approve != -1}
                                                               className="radio-col-deep-orange"/>
                                                        <label htmlFor="md">Multi Days</label></>}
                                                </div>
                                            </div>
                                            {this.state.newLeaveRequest.leave_time === "md" &&
                                            <div className="form-group">
                                                <label className="col-md-12">End Date</label>
                                                <div className="col-md-12 input-group">
                                                    <div className="input-group-addon"><i className="ti-time"></i></div>
                                                    <input type="date" value={newLeaveRequest.end}
                                                           onChange={this.handleChangeEndDate}
                                                           disabled={newLeaveRequest.approve != -1}
                                                           className="form-control form-control-line" name="end"
                                                           id="end"/>
                                                </div>
                                            </div>}
                                            <div className="form-group">
                                                <label className="col-md-12">Reason</label>
                                                <div className="col-md-12 input-group">
                                                    <div className="input-group-addon"><i className="ti-pencil-alt"></i>
                                                    </div>
                                                    <input type="text" value={newLeaveRequest.reason}
                                                           onChange={this.handleChangeReason}
                                                           disabled={newLeaveRequest.approve != -1}
                                                           className="form-control form-control-line" name="reason"
                                                           id="reason"/>
                                                </div>
                                            </div>
                                        </div>
                                        {newLeaveRequest.approve == -1 &&
                                        <div className="modal-footer">
                                            <button type="submit"
                                                    className="btn btn-info waves-effect waves-light">Update
                                            </button>
                                        </div>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
        }
    }
}