import React from "react";
import AuthService from "../services/auth.service";
import IUser from "../types/user.type";

type Props = {};

type State = {
    isModerator: boolean,
    isAdmin: boolean,
    currentUser: IUser,
    rules: string
}

export default class Rules extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isModerator: false,
            isAdmin: false,
            currentUser: {} as IUser,
            rules: ""
        };
    }

    componentDidMount = () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
                isModerator: user.roles == "ROLE_MODERATOR",
                isAdmin: user.roles == "ROLE_ADMIN",
            });
        }

        this.getData();
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        let rules = this.state.rules;
        if (rules !== undefined) {
            const script = document.createElement("script");
            script.src = "assets/js/rules.js";
            script.async = true;
            document.body.appendChild(script);
        }
    }

    getData = () => {
        fetch('./assets/data/rules.data')
            .then(res => {
                return res.text();
            })
            .then(result => {
                    this.setState({
                        rules: result
                    })
                }
            );
    };

    render() {
        const {rules} = this.state;
        return (
            <div dangerouslySetInnerHTML={{__html: rules}} className="container-fluid"/>
        )
    }
}