import AuthService from "./auth.service";
import {AxiosRequestHeaders} from "axios";

export default function authHeader() {
    let user = AuthService.getCurrentUser();
    if (user && user.access_token) {
        return {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Access-Control-Allow-Headers': '*',
            'email': user.email,
            crossDomain: true,
            Authorization: 'Bearer ' + reverseString(reverseString(user.access_token).substring(0, 150))
        } as AxiosRequestHeaders; // for Spring Boot back-end
        // return {'x-access-token': user.accessToken};       // for Node.js Express back-end
    } else {
        return {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Access-Control-Allow-Headers': '*',
            crossDomain: true,
            Authorization: ''
        } as AxiosRequestHeaders;
    }
}

function reverseString(str: string): string {
    let reversed = '';
    for (let i = str.length - 1; i >= 0; i--) {
        reversed += str[i];
    }
    return reversed;
}